import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of, Observable, forkJoin, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { EmployeesProvider } from '@pos-common/services/resources/employees-db-entity.provider';
import { FilterForm } from '@pos-common/classes/calendar/filter-form.class';
import { SecurityService } from '../security.service';
import { finalize, first, switchMap } from 'rxjs/operators';
import { FilterGroup } from '@pos-common/classes/calendar/filter-group.class';
import { FilterItem } from '@pos-common/classes/calendar/filter-item.class';
import {
  CALENDAR_DATE_RANGE,
  CALENDAR_FILTER_ITEM_TYPES,
  CALENDAR_FILTER_STATUS,
  CALENDAR_FILTER_TYPES,
  CALENDAR_TIME_RANGE,
  CALENDAR_VIEWS,
} from '@pos-common/constants';
import { Store } from '@pos-common/classes/store.class';
import { StoresProvider } from '@pos-common/services/resources/stores-db-entity.provider';
import { Employee } from '@pos-common/classes/employee.class';
import { LoadingService } from '../loading.service';
import * as moment from 'moment';
import { AppointmentApiService } from '@pos-common/services/api/appointment-api.service';
import { LOCALE } from '@pos-common/constants/locale.const';
import { IBookableHours, ICalendarDateRange, ICalendarUpdateStatus } from '@pos-common/interfaces';
import { LogService } from '../logger/log.service';
import { query } from '@paymash/capacitor-database-plugin';
import { EmployeesFacadeStore } from '@pos-stores/employees';

@Injectable()
export class CalendarService {
  private filterForms = new Map<CALENDAR_VIEWS, BehaviorSubject<FilterForm>>([
    [CALENDAR_VIEWS.CALENDAR, new BehaviorSubject<FilterForm>(new FilterForm())],
    [CALENDAR_VIEWS.LIST, new BehaviorSubject<FilterForm>(new FilterForm())],
  ]);
  private defaultFilterForms = new Map<CALENDAR_VIEWS, FilterForm>([
    [CALENDAR_VIEWS.CALENDAR, new FilterForm()],
    [CALENDAR_VIEWS.LIST, new FilterForm()],
  ]);
  private events$ = new Map<CALENDAR_VIEWS, Subject<any>>([
    [CALENDAR_VIEWS.CALENDAR, new Subject<any>()],
    [CALENDAR_VIEWS.LIST, new Subject<any>()],
  ]);
  private updateStatus$ = new Subject<ICalendarUpdateStatus>();
  private showSearch$ = new BehaviorSubject<boolean>(false);
  private notificationCount$ = new BehaviorSubject<number>(0);
  private dateRange$ = new Subject<CALENDAR_DATE_RANGE>();
  private currentDate$ = new BehaviorSubject<ICalendarDateRange>({ start: moment().toISOString() });
  private renderCalendar$ = new Subject<void>();
  private readonly logger = this.logService.createLogger('CalendarService');

  constructor(
    private appointmentApiService: AppointmentApiService,
    private employeesProvider: EmployeesProvider,
    private storesProvider: StoresProvider,
    private securityService: SecurityService,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private logService: LogService,
    private employeeFacadeState: EmployeesFacadeStore
  ) {}

  initFilterForms() {
    const { CALENDAR, LIST } = CALENDAR_VIEWS;
    this.setFilterForm(CALENDAR, new FilterForm());
    this.setFilterForm(LIST, new FilterForm());
    forkJoin([this.initCalendarFilterForm(), this.initListFilterForm()]).subscribe((result) => {
      const [calendarFilterForm, listFilterForm] = result;
      this.defaultFilterForms.set(CALENDAR, calendarFilterForm.copy());
      this.defaultFilterForms.set(LIST, listFilterForm.copy());
    });
  }

  getEvents(view: CALENDAR_VIEWS) {
    return this.events$.get(view).asObservable();
  }

  loadEvents(view: CALENDAR_VIEWS) {
    const filterFormSource = this.getParamsSource(view);

    filterFormSource
      .pipe(
        switchMap((filterForm) => {
          const { selectedGroups, isInit, filterParams } = filterForm;
          if (isInit) {
            this.loadingService.showLoadingItem();
          }
          const data = this.getFilterData(selectedGroups);
          if (view === CALENDAR_VIEWS.CALENDAR) {
            return this.appointmentApiService.getCalendarAppintments(filterParams.typeRange, data);
          }
          return this.appointmentApiService.getListAppintments(filterParams, data);
        }),
        finalize(() => {
          this.loadingService.hideLoadingItem();
        })
      )
      .subscribe(
        (resp) => {
          this.events$.get(view).next(resp.data);
        },
        (error) => {
          this.logger.error(error, 'loadEvents:view', undefined, { view });
          this.events$.get(view).next({ appointments: [], employees: [] });
        }
      );
    this.updateNotifications();
  }

  loadBokingSettings() {
    return this.appointmentApiService.getBookingSetting();
  }

  getFilterForm(view: CALENDAR_VIEWS) {
    return this.filterForms.get(view).asObservable();
  }

  getFilterFormValue(view: CALENDAR_VIEWS) {
    return this.filterForms.get(view).getValue();
  }

  getDefaultFilterForm(view: CALENDAR_VIEWS) {
    return this.defaultFilterForms.get(view);
  }

  setFilterForm(view: CALENDAR_VIEWS, filterForm: FilterForm) {
    this.filterForms.get(view).next(filterForm);
  }

  setUpdateStatus(updateStatus: ICalendarUpdateStatus) {
    this.updateStatus$.next(updateStatus);
  }

  getUpdateStatus() {
    return this.updateStatus$.asObservable();
  }

  setDateRange(dateRange: CALENDAR_DATE_RANGE) {
    this.dateRange$.next(dateRange);
  }

  getDateRange() {
    return this.dateRange$.asObservable();
  }

  setCurrentDate(currentDate: ICalendarDateRange) {
    this.currentDate$.next(currentDate);
  }

  getCurrentDate() {
    return this.currentDate$.asObservable();
  }

  setShowSearch(value: boolean) {
    this.showSearch$.next(value);
  }

  getShowSearch() {
    return this.showSearch$.asObservable();
  }

  setRenderCalendar() {
    this.renderCalendar$.next();
  }

  getRenderCalendar() {
    return this.renderCalendar$.asObservable();
  }

  getDateFormat(dateAt?: string) {
    const newDate = dateAt ? moment(dateAt) : moment();
    return newDate.format(LOCALE.DateFormat.YYYY_MM_DD_DASH);
  }

  getDateAt(dateAt: string, isEnd: boolean = false) {
    if (!dateAt) {
      return null;
    }
    const newDate = isEnd ? moment(dateAt).endOf('day') : moment(dateAt);
    return newDate.format(LOCALE.DateFormat.YYYY_MM_DD_HH_mm_DASH);
  }

  getCalendarInterval(date: string, timeRange: CALENDAR_TIME_RANGE, isEnd: boolean = false) {
    if (!date) {
      return null;
    }
    let unitOfTime = timeRange as moment.unitOfTime.StartOf;
    if (timeRange === CALENDAR_TIME_RANGE.week) {
      unitOfTime = 'isoWeek';
    }
    const newDate = isEnd ? moment(date).endOf(unitOfTime) : moment(date).startOf(unitOfTime);
    return newDate.format(LOCALE.DateFormat.YYYY_MM_DD_HH_mm_DASH);
  }

  getNotificationCount() {
    return this.notificationCount$.asObservable();
  }

  setNotificationCount(count: number) {
    this.notificationCount$.next(count);
  }

  updateNotifications() {
    const activeEmployee: Employee = this.employeeFacadeState.activeEmployeeSnapshot;
    this.appointmentApiService.getNotificationCount(activeEmployee.uuid).subscribe((count) => {
      this.setNotificationCount(count);
    });
  }

  getBookableHours(bookableHours: IBookableHours): IBookableHours {
    const [startHours] = bookableHours.start.split(':');
    const [endHours] = bookableHours.end.split(':');
    let start = moment(`${startHours}:00`, 'HH:mm');
    if (startHours !== '00') {
      start.add(-1, 'hour');
    }
    const end = moment(`${endHours}:00`, 'HH:mm').add(1, 'hour');
    const { H24 } = LOCALE.TimeFormat;
    return { start: start.format(H24), end: end.format(H24) };
  }

  private initCalendarFilterForm() {
    const queryParams = query({ deleted: false });
    return forkJoin([this.employeesProvider.getListByParams(queryParams), this.storesProvider.getListByParams(queryParams)]).pipe(
      first(),
      switchMap((listProviders) => {
        const [employees, stores] = listProviders;
        const filterForm = this.getFilterFormValue(CALENDAR_VIEWS.CALENDAR);
        filterForm.employee = this.getEmployeeFilter(employees, true, false);
        filterForm.store = this.getStoreFilter(stores);
        filterForm.interval = this.getIntervalFilter(CALENDAR_VIEWS.CALENDAR, true);
        filterForm.filterParams = { typeRange: CALENDAR_TIME_RANGE.day };

        this.setFilterForm(CALENDAR_VIEWS.CALENDAR, filterForm);
        return of(filterForm);
      })
    );
  }

  private initListFilterForm() {
    const queryParams = query({ deleted: false });
    return forkJoin([this.employeesProvider.getListByParams(queryParams), this.storesProvider.getListByParams(queryParams)]).pipe(
      first(),
      switchMap((listProviders) => {
        const [employees, stores] = listProviders;
        const filterForm = this.getFilterFormValue(CALENDAR_VIEWS.LIST);
        filterForm.employee = this.getEmployeeFilter(employees, true);
        filterForm.status = this.getStatusFilter();
        filterForm.interval = this.getIntervalFilter(CALENDAR_VIEWS.LIST);
        filterForm.amount = this.getAmountFilter();
        filterForm.store = this.getStoreFilter(stores);

        this.setFilterForm(CALENDAR_VIEWS.LIST, filterForm);
        return of(filterForm);
      })
    );
  }

  private getParamsSource(view: CALENDAR_VIEWS): Observable<FilterForm> {
    let filterFormSource: Observable<FilterForm> = null;
    const { CALENDAR, LIST } = CALENDAR_VIEWS;

    if (view === CALENDAR) {
      const calendarFilterForm = this.getFilterFormValue(CALENDAR);
      filterFormSource = of(calendarFilterForm);
      if (calendarFilterForm.isInit) {
        filterFormSource = this.initCalendarFilterForm();
        calendarFilterForm.isInit = false;
      }
      return filterFormSource;
    }

    const listFilterForm = this.getFilterFormValue(LIST);
    filterFormSource = of(listFilterForm);
    if (listFilterForm.isInit) {
      filterFormSource = this.initListFilterForm();
      listFilterForm.isInit = false;
    }
    return filterFormSource;
  }

  private getEmployeeFilter(employees: Employee[], isOpen: boolean, canOpen: boolean = true) {
    const activeEmployee = this.employeeFacadeState.activeEmployeeSnapshot;
    const activeStore = this.securityService.getActiveStore();

    const filterGroup = new FilterGroup(CALENDAR_FILTER_TYPES.EMPLOYEE, 'calendar_filter_employee', isOpen);
    filterGroup.canOpen = canOpen;
    filterGroup.items = employees
      .filter((employee) => {
        return employee.stores.some((store) => store.uuid === activeStore.uuid);
      })
      .map((employee) => {
        const filterItem = new FilterItem();
        filterItem.value = { uuid: employee.uuid };
        filterItem.title = `${employee.firstName} ${employee.lastName}`;
        filterItem.isSelected = activeEmployee?.hasAdminPermission || employee?.uuid === activeEmployee?.uuid;
        filterItem.name = 'employees';
        return filterItem;
      });
    const notAssigned = new FilterItem();
    notAssigned.title = this.translateService.instant('common_not_assigned');
    notAssigned.name = 'employees';
    notAssigned.value = { uuid: null };
    notAssigned.isSelected = activeEmployee?.hasAdminPermission;
    filterGroup.items = [notAssigned, ...filterGroup.items];
    return filterGroup;
  }

  private getStoreFilter(stores: Store[]) {
    const activeStore = this.securityService.getActiveStore();

    const filterGroup = new FilterGroup(CALENDAR_FILTER_TYPES.STORE, 'calendar_filter_store');
    filterGroup.isHidden = true;
    filterGroup.items = stores.map((store) => {
      const filterItem = new FilterItem();
      filterItem.value = { uuid: store.uuid };
      filterItem.title = store.name;
      filterItem.isSelected = store.uuid === activeStore.uuid;
      filterItem.name = 'stores';
      return filterItem;
    });
    return filterGroup;
  }

  private getStatusFilter() {
    const filterGroup = new FilterGroup(CALENDAR_FILTER_TYPES.STATUS, 'calendar_filter_status');
    filterGroup.items = Object.keys(CALENDAR_FILTER_STATUS).map((key) => {
      const filterItem = new FilterItem();
      filterItem.value = CALENDAR_FILTER_STATUS[key];
      filterItem.title = this.translateService.instant(`calendar_filter_status_${key.toLocaleLowerCase()}`);
      filterItem.name = 'statuses';
      filterItem.isSelected = true;
      return filterItem;
    });
    return filterGroup;
  }

  private getAmountFilter() {
    const filterGroup = new FilterGroup(CALENDAR_FILTER_TYPES.AMOUNT, 'calendar_filter_amount');
    filterGroup.itemType = CALENDAR_FILTER_ITEM_TYPES.AMOUNT;
    const fromFilter = new FilterItem();
    fromFilter.title = 'From';
    fromFilter.value = '';
    fromFilter.name = 'amountRangeMin';

    const toFilter = new FilterItem();
    toFilter.title = 'To';
    toFilter.value = '';
    toFilter.name = 'amountRangeMax';

    filterGroup.items = [fromFilter, toFilter];
    return filterGroup;
  }

  private getIntervalFilter(view: CALENDAR_VIEWS, isHidden: boolean = false) {
    const { CALENDAR } = CALENDAR_VIEWS;
    const filterGroup = new FilterGroup(CALENDAR_FILTER_TYPES.INTERVAL, 'calendar_filter_interval');

    filterGroup.isHidden = isHidden;
    filterGroup.itemType = CALENDAR_FILTER_ITEM_TYPES.DATE;

    const today = moment().format(LOCALE.DateFormat.YYYY_MM_DD_DASH);
    const fromFilter = new FilterItem();
    fromFilter.title = 'From';
    fromFilter.value = view === CALENDAR ? this.getCalendarInterval(today, CALENDAR_TIME_RANGE.day) : '';
    fromFilter.name = 'startedAt';
    fromFilter.isSelected = view === CALENDAR;

    const toFilter = new FilterItem();
    toFilter.title = 'To';
    toFilter.value = view === CALENDAR ? this.getCalendarInterval(today, CALENDAR_TIME_RANGE.day, true) : '';
    toFilter.name = 'endedAt';
    toFilter.isSelected = view === CALENDAR;

    filterGroup.items = [fromFilter, toFilter];
    return filterGroup;
  }

  private getFilterData(selectedGroups: FilterGroup[]): any {
    const queryBuilder = selectedGroups.reduce(
      (query, group) => {
        group.getSelectedItems().forEach((item) => {
          if (group.itemType === CALENDAR_FILTER_ITEM_TYPES.DATE) {
            const isEndedAt = item.name === 'endedAt';
            query[item.name] = this.getDateAt(item.value as string, isEndedAt);
            return query;
          }
          const { value } = item;
          if (group.type === CALENDAR_FILTER_TYPES.AMOUNT) {
            query.filters[item.name] = parseFloat(value as string);
            return query;
          }
          if (query.filters[item.name]) {
            query.filters[item.name] = [...query.filters[item.name], value];
            return;
          }
          query.filters[item.name] = [value];
        });
        return query;
      },
      { filters: {} }
    );

    return queryBuilder;
  }
}
