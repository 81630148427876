import { Image } from './image.class';
import { Entity } from './entity.class';
import { Address } from './address.class';

/**
 * Created by y.belinsky on 8/16/17.
 */

export class Company extends Entity {
  id: number;
  uuid: string;
  hashedId: string;
  isRKSVEnabled: boolean = false;
  isKassenSichVEnabled: boolean = false;
  name: string;
  description: string;
  address: Address;
  locale: {
    dateFormat: string;
    timeFormat: string;
    startWeekOn: string;
    numberFormat: string;
    currency: string;
    currencyRounding: string;
    unitSystem: string;
    weightUnit: string;
  };
  email: string;
  receiptText: string;
  vatNumber: string;
  isCashRegisterEnabled: boolean = false;
  forceShiftOpeningAndClosing: boolean = false;
  isQRCodeOnReceiptEnabled: boolean = false;
  isRestaurantEnabled: boolean = false;
  tablesEnforced: boolean = false;
  isPickupDeliveryEnabled: boolean = false;
  hasActiveDiscounts: boolean = false;
  defaultPaymentMethod: { uuid: string };
  defaultVATRate: { uuid: string };
  meta: any;
  image: Image;
  isMigrated: boolean;
  openingHours: string;
  isTakeAwayEnabled: boolean;
  defaultTakeAwayCompanyVATRate: { uuid: string };
  isGiftCardsEnabled: boolean;
  isInvoiceNet: boolean;
  isBookingEnabled: boolean;
  isWebshopEnabled: boolean;
  isSelfOrderingEnabled?: boolean

  constructor(data) {
    super(data);
    this.id = data.id;
    this.hashedId = data.hashedId;
    this.isRKSVEnabled = data.isRKSVEnabled;
    this.isKassenSichVEnabled = data.isKassenSichVEnabled || false;
    this.name = data.name;
    this.description = data.description || null;
    this.address = data.address;
    this.locale = data.locale;
    this.email = data.email;
    this.receiptText = data.receiptText;
    this.vatNumber = data.vatNumber;
    this.isCashRegisterEnabled = data.isCashRegisterEnabled;
    this.isQRCodeOnReceiptEnabled = data.isQRCodeOnReceiptEnabled;
    this.isRestaurantEnabled = data.isRestaurantEnabled;
    this.tablesEnforced = data.tablesEnforced;
    this.isPickupDeliveryEnabled = data.isPickupDeliveryEnabled;
    this.hasActiveDiscounts = data.hasActiveDiscounts;
    this.defaultPaymentMethod = data.defaultPaymentMethod;
    this.defaultVATRate = data.defaultVATRate;
    this.meta = data.meta;
    this.image = data.image ? new Image(data.image) : null;
    this.isMigrated = data.isMigrated || false;
    this.openingHours = data.openingHour;
    this.isTakeAwayEnabled = data.isTakeAwayEnabled || false;
    this.defaultTakeAwayCompanyVATRate = data.defaultTakeAwayCompanyVATRate || null;
    this.isGiftCardsEnabled = data.isGiftCardsEnabled || false;
    this.isInvoiceNet = data.isInvoiceNet || false;
    this.isBookingEnabled = data.isBookingEnabled || false;
    this.isWebshopEnabled = data.isWebshopEnabled || false;
    this.isSelfOrderingEnabled = data.isSelfOrderingEnabled || false;
    this.forceShiftOpeningAndClosing = data.forceShiftOpeningAndClosing || false;
  }

  get isRequireOnlineInvoiceProcessing(): boolean {
    return this.isRKSVEnabled || this.isKassenSichVEnabled;
  }
}
