import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { SecurityService } from '@pos-common/services/system/security.service';

import { InvoicesState } from './invoices.reducer';
import { hasOpenOpenInvoiceByUuidStore, selectCounterOpenInvoiceByUuidStore } from './invoices.selectors';
import {
  ClearOpenSelfOrderInvoices,
  LoadSelfOrderInvoicesFromLocalDB,
  ReceivedNewOpenSelfOrderInvoice,
} from './invoices.actions';
import { Actions, ofType } from '@ngrx/effects';


@Injectable()
export class InvoicesFacadeStore {

  constructor(
    private store: Store<InvoicesState>,
    private securityService: SecurityService,
    private actions$: Actions
  ) { }

  public get counterOpenSelfOrderInvoicesActiveStore(): Observable<number> {
    return this.store.select(selectCounterOpenInvoiceByUuidStore(this.securityService.activeStore?.uuid));
  }

  public get hasOpenOpenInvoiceByUuidStore(): Observable<boolean> {
    return this.store.select(hasOpenOpenInvoiceByUuidStore(this.securityService.activeStore?.uuid));
  }

  public get handleNewSelfOrderInvoice(): Observable<boolean> {
    return this.actions$.pipe(
      ofType(ReceivedNewOpenSelfOrderInvoice),
      map(({ isNew }): boolean => isNew)
    )
  }

  public loadSelfOrderInvoiceFromLocalDb(): void {
    this.store.dispatch(LoadSelfOrderInvoicesFromLocalDB());
  }

  public clearSelfOrderInvoice(): void {
    this.store.dispatch(ClearOpenSelfOrderInvoices());
  }

}
