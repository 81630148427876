import { Invoice } from '@pos-common/classes/invoice.class';
import { InvoiceEntry } from '@pos-common/classes/invoice-entry.class';
import { InvoicePayment } from '@pos-common/classes/invoice-payment.class';
import { SelfOrderInInvoiceInterface } from '@pos-common/interfaces';

export class InvoiceForLogsClass {
  // general
  public uuid: string;
  public isDraft: boolean;
  public isPaid: boolean;
  public isPartiallyPaid: boolean;
  public invoiceId: string;
  public sequentId: number;
  public isPrinted: boolean;
  public amount: number;
  public amountNotRounded: number;
  public modificationDate: string;
  public salesChannel: string;
  public subChannel: string;
  // other / references
  public employee: any;
  public customer: any;
  public customerUuid: string;
  public store: any;
  public isWebshop: boolean;
  // gastronomy
  public gastronomyTable: { uuid: string };
  public gastronomyTableName: string;
  // invoices entries/payments
  public invoiceEntries: Partial<InvoiceEntry>[] = [];
  public invoicePayments: Partial<InvoicePayment>[] = [];
  // cancellation
  public isCancelled: boolean;
  public isPartiallyCancelled: boolean;
  public cancellationStatus: string;
  public cancellingEmployee: any;
  public sourceUuid: string;
  // selfOrder info invoices
  public selfOrder?: SelfOrderInInvoiceInterface;
  //Refund
  public isRefund: boolean;
  public refundedAmount: number;
  public isSeparated: boolean;
  public invoiceType: string;
  public deleted: boolean;
  public rounding: number;

  constructor(invoice: Invoice) {
    this.uuid = invoice?.uuid;
    this.isDraft = invoice?.isDraft;
    this.isPaid = invoice?.isPaid;
    this.isPartiallyPaid = invoice?.isPartiallyPaid;
    this.invoiceId = invoice?.invoiceId;
    this.sequentId = invoice?.sequentId;
    this.isPrinted = invoice?.isPrinted;
    this.amount = invoice?.amount;
    this.amountNotRounded = invoice?.amountNotRounded;
    this.modificationDate = invoice?.modificationDate;
    this.salesChannel = invoice?.salesChannel;
    this.subChannel = invoice?.subChannel;
    this.employee = { uuid: invoice?.employee?.uuid };
    this.customer = invoice?.customer;
    this.customerUuid = invoice?.customerUuid;
    this.store = invoice?.store;
    this.isWebshop = invoice?.isWebshop;
    this.gastronomyTable = invoice?.gastronomyTable;
    this.gastronomyTableName = invoice?.gastronomyTableName;
    this.invoiceEntries = this.generateShortInvoiceEntry(invoice?.invoiceEntries);
    this.invoicePayments = this.generatePayment(invoice?.invoicePayments);
    this.isCancelled = invoice?.isCancelled;
    this.isPartiallyCancelled = invoice?.isPartiallyCancelled;
    this.cancellationStatus = invoice?.cancellationStatus;
    this.cancellingEmployee = invoice?.cancellingEmployee;
    this.sourceUuid = invoice?.sourceUuid;
    this.selfOrder = invoice?.selfOrder;
    this.isRefund = invoice?.isRefund;
    this.refundedAmount = invoice?.refundedAmount;
    this.isSeparated = invoice?.isSeparated;
    this.invoiceType = invoice?.invoiceType;
    this.deleted = invoice?.deleted;
    this.rounding = invoice?.rounding;
  }

  private generateShortInvoiceEntry(entries: InvoiceEntry[]): Partial<InvoiceEntry>[] {
    return entries ? entries.map((entry: InvoiceEntry): Partial<InvoiceEntry> => ({
      uuid: entry?.uuid,
      name: entry?.name,
      type: entry?.type,
      quantity: entry.quantity,
      quantityForKitchenReceipt: entry?.quantityForKitchenReceipt,
      price: entry?.price,
      totalAmount: entry?.totalAmount,
      deleted: entry?.deleted,
      modificationDate: entry?.modificationDate,
      totalDiscountAmount: entry?.totalDiscountAmount,
      employee: { uuid: entry?.employee?.['uuid'] },
      customerIdentifier: entry?.customerIdentifier,
      productVariant: { uuid: entry?.productVariant?.uuid },
      store: { uuid: entry?.store?.['uuid'] },
      giftCardCode: entry?.giftCardCode,
      guestNumber: entry?.guestNumber,
    })) : []
  }

  private generatePayment(payments: InvoicePayment[]): Partial<InvoicePayment>[] {
      return payments ? payments.map((payment: InvoicePayment): Partial<InvoicePayment> => ({
        uuid: payment?.uuid,
        method: payment?.method,
        amount: payment?.amount,
        deleted: payment?.deleted,
        paymentProvider: payment?.paymentProvider,
        date: payment?.date,
        terminalTransaction: payment?.terminalTransaction,
        giftCard: { uuid: payment?.giftCard?.uuid }
      })) : []
  }
}
