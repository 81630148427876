import { PAYMASH_PROFILE_COMMON } from './profile.common';
const profile: Partial<typeof PAYMASH_PROFILE_COMMON> = {
  APP_ENV: 'production',
  isProduction: true,
  SERVER_URL: 'https://app.mypaymash.com',
  sentryEnvironment: 'production',
  /**
   * @note Policy for Action: "s3:PutObject", Resource: "arn:aws:s3:::paymash/pos/*"
   */
  ASW: "MTIzLDM0LDk3LDM0LDU4LDEyMywzNCw5Nyw5OSw5OSwxMDEsMTE1LDExNSw3NSwxMDEsMTIxLDczLDEwMCwzNCw1OCwzNCw2NSw3NSw3Myw2NSw4NCw4OSw4Nyw1Myw2OSw1NSw3MSw1NSw4NSw1NSw2NSw4Myw4Niw1Myw2Niw1MiwzNCw0NCwzNCwxMTUsMTAxLDk5LDExNCwxMDEsMTE2LDY1LDk5LDk5LDEwMSwxMTUsMTE1LDc1LDEwMSwxMjEsMzQsNTgsMzQsMTE2LDkwLDExOCwxMDgsMTAzLDU2LDEwNSw3Niw3Niw3NSwxMDksOTcsNzUsMTA2LDg3LDExNCw1NCw0Nyw3NCw3NSwxMTksNTMsMTA2LDExMCw3Miw1MywxMDYsOTAsOTgsOTAsNDgsNTcsMTE1LDEwMCwxMTEsODIsMTA2LDU3LDc3LDEwNywzNCw0NCwzNCwxMTQsMTAxLDEwMywxMDUsMTExLDExMCwzNCw1OCwzNCwxMDEsMTE3LDQ1LDk5LDEwMSwxMTAsMTE2LDExNCw5NywxMDgsNDUsNDksMzQsMTI1LDQ0LDM0LDk4LDM0LDU4LDEyMywzNCw2NiwxMTcsOTksMTA3LDEwMSwxMTYsMzQsNTgsMzQsMTEyLDk3LDEyMSwxMDksOTcsMTE1LDEwNCwzNCwxMjUsMTI1",
  LOGGER: {
    url: 'https://logs.fabware.com:45645',
    username: 'Mokohzik4IoFuYgh_X2Z8HP',
    password: '76L9oyKRTRHFLj5SUS17l-Z',
  },
  sendingLogsDelay: 1,
  adyen: {
    managementUrl: 'https://management-live.adyen.com',
    terminalUrl: 'https://terminal-api-live.adyen.com',
  },
  intercom: {
    appId: 'sibuyuid',
    appType: 'Paymash POS Hybrid',
  },
  sumup: {
    apiKey: '03f3ee1f-3521-4d0f-a6b4-f63969e183d7',
  },
};
export const PAYMASH_PROFILE: Partial<typeof PAYMASH_PROFILE_COMMON> = Object.assign(PAYMASH_PROFILE_COMMON, profile);
