import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, ChangeDetectorRef, NgModule } from '@angular/core';
import { IMAGE_SIZES } from '@pos-common/classes/image.class';
import { ProductCategory } from '@pos-common/classes/product-category.class';
import { ProductItem } from '@pos-common/classes/product-item.class';
import { ProductVariant } from '@pos-common/classes/product-variant.class';
import { Product } from '@pos-common/classes/product.class';
import { PRODUCT_VARIANT_LIST_TYPE } from '@pos-common/constants';
import { PAGINATION } from '@pos-common/constants/pagination.const';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { ProductCategoryProvider } from '@pos-common/services/resources/product-category-db-entity.provider';
import { ProductProvider } from '@pos-common/services/resources/product-db-entity.provider';
import { ProductVariantProvider } from '@pos-common/services/resources/product-variant-db-entity.provider';
import { AppointmentModalService } from '@pos-common/services/system/appointment-modal/appointment-modal.service';
import { AppointmentService } from '@pos-common/services/system/appointment/appointment.service';
import { CartService } from '@pos-common/services/system/cart.service';
import { CollectionViewService } from '@pos-common/services/system/collection-view.service';
import { InvoicesService } from '@pos-common/services/system/invoices.service';
import { KeyboardService } from '@pos-common/services/system/keyboard/keyboard.service';
import { LogService } from '@pos-common/services/system/logger/log.service';
import { ModalService } from '@pos-common/services/system/modal.service';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { SecurityService } from '@pos-common/services/system/security.service';
import { TippingService } from '@pos-common/services/system/tipping/tipping.service';
import { ProductDetailsModal } from 'pages/collection-view/product-details-modal/product-details-modal.component';
import { InfiniteScrollBaseComponent } from '../infinite-scroll-base/infinite-scroll-base.component';
import { arrayContains, by, contains, group, notEquals, oneOf, or, query } from '@paymash/capacitor-database-plugin';
import { IInventoryEvent } from '@pos-common/interfaces/inventory-event.interface';
import { SearchUtils } from '@pos-common/services';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LoaderComponentModule } from '../loader/loader.component';
import { SearchComponentModule } from '../search/search.component';
import { ProductSearchListComponentModule } from '../product-search-list/product-search-list.component';
import { ILoadDataOptions } from '@pos-common/interfaces';

@Component({
  selector: 'pos-inventory-product-search',
  templateUrl: './inventory-product-search.component.html',
  styleUrls: ['./inventory-product-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InventoryProductSearchComponent extends InfiniteScrollBaseComponent<ProductItem> implements OnInit {
  @Input() types: string[] = [];
  public placeholderSearch: string;
  public placeholderNoResult: string;
  public showAutofocus = false;
  private readonly logger = this.logService.createLogger('InventoryProductSearchComponent');

  get IMAGE_SIZES() {
    return IMAGE_SIZES;
  }

  get isServiceList() {
    return this.types.some((type) => type === PRODUCT_TYPES.SERVICE);
  }

  protected loadMoreCount = PAGINATION.PRODUCTS_ITEMS_COUNT;

  constructor(
    protected cdr: ChangeDetectorRef,
    private tippingService: TippingService,
    private securityService: SecurityService,
    private productProvider: ProductProvider,
    private productVariantProvider: ProductVariantProvider,
    private productCategoryProvider: ProductCategoryProvider,
    private modalService: ModalService,
    private appointmentModalService: AppointmentModalService,
    private appointmentService: AppointmentService,
    private invoicesService: InvoicesService,
    private cartService: CartService,
    private collectionViewService: CollectionViewService,
    private keyboardService: KeyboardService,
    private platformService: PlatformService,
    private searchUtils: SearchUtils,
    private logService: LogService
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.setPlaceholders();
    super.ngOnInit();
  }

  handleSearchValue(value: string) {
    value = this.searchUtils.replaceForSearch(value);
    this.searchValue = value;
    super.handleSearchValue(value);
  }

  clearSearch() {
    this.showLoader();
  }

  async productLongClick(productItem: ProductItem) {
    const product = await this.findProductFromDatabase(productItem.uuid);
    if (this.isServiceList) {
      return this.openCustomerServiceModal(product);
    }
    this.openProductDetailsModal(product, productItem.productCategoryUuid);
  }

  async productClick(event: IInventoryEvent) {
    const { dataset: productItem, eventTarget } = event;
    const { productCategoryUuid } = productItem;
    const product: Product = await this.findProductFromDatabase(productItem.uuid);
    if (!product?.hasCustomOptions && !product?.forcePriceInputOnSale) {
      const uuid: string = product?.variants?.[0]?.uuid;
      this.productVariantProvider
        .getByUuid(uuid)
        .toPromise()
        .then((variant: ProductVariant) => {
          if (this.isServiceList) {
            this.openProductVariantModalWithDelay(product, productCategoryUuid, eventTarget);
            return;
          }
          this.addProductToCart(variant, product, productCategoryUuid, '');
        })
        .catch((err) => this.logger.error(err, 'productClick:DbDaoService:getDataByUUID:'));
    } else {
      this.productVariantProvider
        .getListByParams({ productUuid: product.uuid })
        .toPromise()
        .then((variants) => {
          if ((variants && variants.length > 1) || product?.forcePriceInputOnSale || this.isServiceList) {
            this.openProductVariantModalWithDelay(product, productCategoryUuid, eventTarget);
            return;
          }
          const variant = new ProductVariant(variants[0]);
          const shortName = variant.getVariantConcatenatedName();
          this.addProductToCart(variant, product, productCategoryUuid, shortName);
        })
        .catch(() => {
          this.openProductVariantModalWithDelay(product, productCategoryUuid, eventTarget);
        });
    }
  }

  protected loadData({ start, end, searchValue }: ILoadDataOptions): Promise<any[]> {
    let productList: Product[] = [];
    return this.getProducts(start, end, searchValue)
      .then((products) => {
        productList = products;
        const allProductCategories = products.map((product) => product.getCategoriesList());
        const productCategories = [].concat(...allProductCategories).filter((productCategory) => productCategory !== 'root');
        return this.getCategories(productCategories);
      })
      .then((categories) => {
        this.isLoading = false;
        this.showAutofocus = true;
        const result = productList.map((product) => this.addProductsToUiList(product, categories));
        const products = [].concat(...result);
        return products;
      });
  }

  private getProducts(start: number, end: number, searchValue: string): Promise<Product[]> {
    const queryParams = query({ visible: true });

    if (this.tippingService.isTipping && this.tippingService.productUuid) {
      queryParams.push({ uuid: notEquals(this.tippingService.productUuid) });
    }

    const activeStore = this.securityService.getActiveStore();
    if (activeStore?.uuid) {
      queryParams.push(group({ 'stores.uuid': activeStore.uuid }, or({ stores: arrayContains(activeStore.uuid) })));
    }

    if (this.types.length) {
      queryParams.push({ type: oneOf(...this.types) });
    }

    if (searchValue) {
      queryParams.push({ fieldToSearch: contains(searchValue) });
    }

    const options = {
      offset: start,
      limit: end,
      order: [by('fieldToSort')],
      select: ['uuid', 'visible', 'isNew', 'wasPrice', 'price', 'bgColor', 'name', 'images', 'productCategoriesUuid'],
    };

    return this.productProvider.getListByParams(queryParams, options).toPromise();
  }

  private getCategories(productCategories: string[]): Promise<any[]> {
    const queryParams = query({ uuid: oneOf(...productCategories), visible: true });

    return this.productCategoryProvider.getListByParams(queryParams).toPromise();
  }

  private addProductsToUiList(product: Product, categories: ProductCategory[] = []) {
    let productCategories = product.getCategoriesList();
    if (!productCategories.length) {
      productCategories = [null];
    }

    return productCategories.map((productCategory) => this.addProductToUiList(product, productCategory, categories));
  }

  private addProductToUiList(currentProduct: Product, categoryUuid: string, categories: ProductCategory[]) {
    const productItem = new ProductItem(currentProduct, categoryUuid);
    productItem.name = currentProduct.name;
    if (categoryUuid) {
      const category = categories.find((category) => category.uuid === categoryUuid);
      if (category?.visible) {
        productItem.name = `${productItem.name} (${category.name})`;
      }
    }
    return productItem;
  }

  private openProductVariantModalWithDelay(product: Product, productCategoryUuid: string, eventTarget: any) {
    if (!this.platformService.isMobile && this.keyboardService.isOpen) {
      this.keyboardService.getCloseEventOnce().subscribe(() => this.openProductVariantModal(product, productCategoryUuid, eventTarget));
      return;
    }

    this.openProductVariantModal(product, productCategoryUuid, eventTarget);
  }

  private openProductVariantModal(product: Product, productCategoryUuid: string, eventTarget: any) {
    const listType = this.isServiceList ? PRODUCT_VARIANT_LIST_TYPE.SEARCH_SERVICES : PRODUCT_VARIANT_LIST_TYPE.SEARCH;
    this.collectionViewService.setProducVariantModalData({
      product,
      eventTarget,
      listType,
      productCategoryUuid,
    });
  }

  // TODO MOVE REPEATED CODE TO SERVICE
  private async openProductDetailsModal(product: Product, productCategoryUuid: string) {
    const productDetailsModal = await this.modalService.presentModal(ProductDetailsModal, {
      product,
      productCategory: productCategoryUuid,
      source: 'collection',
    });
    await productDetailsModal.present();
  }

  // TODO MOVE REPEATED CODE TO SERVICE
  private openCustomerServiceModal(product: Product) {
    let service = this.appointmentService.makeServiceToAddData(product, product?.price, null);
    service = this.appointmentService.createService(service);
    this.appointmentModalService
      .openCustomServiceModal(service, true)
      .then((data) => this.appointmentService.setCustomService(service, data));
  }

  private addProductToCart(variant: ProductVariant, product: Product, productCategoryUuid: string, productShortName: string) {
    const dataToAdd = this.invoicesService.makeInvoiceEntryToAddData(variant, product, productShortName, productCategoryUuid);
    dataToAdd.image = this.cartService.getEntryImage(product, variant);
    this.cartService.addEntry(dataToAdd, product.type, 1);
  }

  private setPlaceholders() {
    this.placeholderSearch = this.isServiceList ? 'inventory_search_service_placeholder' : 'inventory_search_product_placeholder';
    this.placeholderNoResult = this.isServiceList ? 'inventory_search_no_services' : 'inventory_search_no_products';
  }

  private findProductFromDatabase(uuid: string): Promise<Product> {
    return this.productProvider.getByUuid(uuid).toPromise();
  }
}
@NgModule({
  imports: [CommonModule, IonicModule, LoaderComponentModule, SearchComponentModule, ProductSearchListComponentModule],
  declarations: [InventoryProductSearchComponent],
  exports: [InventoryProductSearchComponent],
})
export class InventoryProductSearchComponentModule {}
