import { BehaviorSubject, from, interval, Observable, of, Subject, zip } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DbDaoService } from '../db/db-dao.service';
import { CustomersApiService } from '../api/customers-api.service';
import { InvoicesApiService } from '../api/invoices-api.service';
import { SecurityService } from './security.service';
import { UPDATES_TYPES } from '../../constants/updates-types.const';
import { Customer } from '../../classes/customer.class';
import { Invoice } from '../../classes/invoice.class';
import { NetworkService } from './network.service';
import { SYNC_ITEM_STATES, SYNC_STATUSES } from '../../constants/sync-statuses.const';
import { ServerError } from '../../interfaces';
import { SyncListItem } from '../../classes/sync-list-item.class';
import { Entity } from '../../classes/entity.class';
import { SecuredResponse } from '../../classes/secured-response.class';
import { SERVER_RESPONSE_ERRORS } from '../../constants/server-response-errors.const';
import * as moment from 'moment';
import { LogService } from './logger/log.service';
import { PAYMASH_PROFILE } from '@profile';
import { oneOf, Query, query } from '@paymash/capacitor-database-plugin';
import { SetTimeoutUtil } from '../utils/settimeout.utils';
import { ErrorLevel } from '@spryrocks/logger';

export interface SyncError {
  status: number;
  error: ServerError;
  syncItem: SyncListItem;
}

@Injectable()
export class SyncService {
  private readonly syncDatabases = [UPDATES_TYPES.Customer.type, UPDATES_TYPES.Invoice.type];
  public readonly synchronizationInProgressSubject = new BehaviorSubject<boolean>(false);
  public readonly synchronizationCountSubject: Subject<number> = new BehaviorSubject<number>(0);
  public readonly dataToSaveSubject: Subject<any> = new Subject();
  private readonly synchronizeSubject = new Subject<void>();
  private readonly logger = this.logService.createLogger('SyncService');

  constructor(
    private readonly CustomersApiService: CustomersApiService,
    private readonly DbDaoService: DbDaoService,
    private readonly SecurityService: SecurityService,
    private readonly networkService: NetworkService,
    private readonly InvoicesApiService: InvoicesApiService,
    private readonly setTimeoutUtil: SetTimeoutUtil,
    private readonly logService: LogService
  ) {
    this.onInit();
  }

  private onInit() {
    this.networkService.isOfflineSubject.pipe(filter((isOffline) => !isOffline)).subscribe(this.startSynchronization.bind(this));
    this.synchronizeSubject
      .pipe(
        mergeMap(() => from(this.updateItemsCount())),
        mergeMap((count) =>
          from(this.synchronizeItems(count)).pipe(
            catchError((error) => {
              this.logger.error(error, 'Catch error in mergeMap and synchronizeItems');
              return of();
            })
          )
        )
      )
      .subscribe();

    const hour = 1000 * 60 * 60;
    interval(hour).subscribe(this.startSynchronization.bind(this));
  }

  private getSyncData(): Observable<SyncListItem[]> {
    return this.getSynchronizationSources().pipe(map((data) => this.syncItemsOrdering(data)));
  }

  public startSynchronization(): void {
    this.logger.debug('startSynchronization process');
    const currentCompany = this.SecurityService.getLoggedCompanyData();

    if (currentCompany) {
      this.synchronize();
    }
  }

  private synchronize() {
    this.logger.debug('synchronize next');
    this.synchronizeSubject.next();
  }

  private getSynchronizationSources() {
    return zip(...this.syncDatabases.map((database) => this.getSyncDataByType(database)));
  }

  private async synchronizeItems(count: number): Promise<void> {
    this.logger.debug('synchronizeItems: start with count of data', { count });
    this.synchronizationCountSubject.next(count);

    if (this.isSynchronizationInProgress || this.isOffline || !count) {
      this.logger.info('synchronizeItems: return with isSynchronizationInProgress, isOffline, noCount', {
        isSynchronizationInProgress: this.isSynchronizationInProgress,
        isOffline: this.isOffline,
        noCount: !count,
      });
      return;
    }

    const data = await this.getSyncData().toPromise();

    try {
      this.isSynchronizationInProgress = SYNC_STATUSES.IN_PROGRESS;
      for (const current of data) {
        await this.synchronizeItem(current);
      }
    } catch (error) {
      this.logger.error(error, 'synchronizeItems: error');
    } finally {
      this.isSynchronizationInProgress = SYNC_STATUSES.NOT_IN_PROGRESS;
    }
  }

  private async synchronizeItem(current: SyncListItem) {
    this.logger.debug('synchronizeItem: uuid start synchronize', { currentUuid: current?.uuid });
    if (!current.syncInProgress) {
      current.syncInProgress = true;
      try {
        await this.updateItem(current, SYNC_ITEM_STATES.IS_SYNCHRONIZING);

        const retryInterval = this.prepareRetryInternal(current);
        this.logger.debug('updateDataInSyncList: uuid, retryCount, retryInterval', {
          currentUuid: current?.uuid,
          retryCount: current.retryCount,
          retryInterval,
        });

        if (retryInterval !== 0) {
          this.logger.debug('synchronizeItem: uuid performItemUpdate with setTimeout', { currentUuid: current.uuid, retryInterval });
          await this.setTimeoutUtil.waitTimeAndDo(retryInterval);
        }
        await this.performItemUpdate(current);
      } catch (err) {
        this.logger.error(err, 'synchronizeItem: uuid error', { level: ErrorLevel.Medium }, { currentUuid: current.uuid });
      }
    }
    this.logger.info('synchronizeItem: uuid finish synchronize', { currentUuid: current.uuid });
  }

  private updateItemsCount() {
    const query = this.createGetSyncDataByTypeQuery();
    return Promise.all(this.syncDatabases.map((database) => this.DbDaoService.count(database, query)))
      .then((data) => data.reduce((totalCount, count) => totalCount + count, 0))
      .catch(() => 0);
  }

  private async performItemUpdate(current: SyncListItem): Promise<void> {
    this.logger.debug('performItemUpdate: uuid start', { currentUuid: current.uuid });
    if (current.deleted && current.type !== UPDATES_TYPES.Customer.type) {
      await this.performItemUpdateDeleted(current);
      return;
    }

    const data = await this.DbDaoService.getDataByUUID(current.type, current.uuid);

    try {
      let serverResponse: SecuredResponse | undefined = undefined;
      if (current.type === UPDATES_TYPES.Customer.type) {
        // try to update customer on SERVER
        serverResponse = await this.CustomersApiService.upsertCustomer(new Customer(data.data));
      } else if (current.type === UPDATES_TYPES.Invoice.type) {
        // try to update invoice on SERVER
        serverResponse = await this.InvoicesApiService.upsertInvoice(new Invoice(data.data));
      }

      if (serverResponse) {
        this.logger.info('currentType has been updated on the server', { currentType: current.type, currentUuid: current.uuid });
        await this.removeDataFromSyncList(current);
        if ((serverResponse.status === 200 || serverResponse.status === 201) && serverResponse.data) {
          this.dataToSaveSubject.next(serverResponse.data);
        }
      }
      this.logger.info('performItemUpdate: uuid end', { currentUuid: current.uuid });
    } catch (error) {
      this.logger.error(error, 'performItemUpdate: uuid error', undefined, { currentUuid: current.uuid });
      await this.handleSyncError({ status: error.status, error: data['data'], syncItem: current });
    }
  }

  private async performItemUpdateDeleted(current: SyncListItem) {
    try {
      this.logger.debug('performItemUpdateDeleted: uuid', { currentUuid: current.uuid });
      switch (current.type) {
        case UPDATES_TYPES.Customer.type:
          await this.CustomersApiService.deleteCustomer(current.uuid);
          break;
        case UPDATES_TYPES.Invoice.type:
          await this.InvoicesApiService.deleteInvoiceDraft(current.uuid);

          break;
      }
    } catch (error) {
      await this.handleSyncError({ status: error.status, error: error['data'], syncItem: current });
      return;
    }
    this.logger.info('Uuid was removed from server', { currentType: current.type, currentUuid: current.uuid });
    await this.removeDataFromSyncList(current);
  }

  public async updateItem(item: SyncListItem, state: string): Promise<void> {
    this.logger.debug('updateItem: type uuid start', { type: item.type, uuid: item.uuid });
    try {
      const count = await this.DbDaoService.count(item.type, { uuid: item.uuid });
      if (!count) {
        this.logger.info('updateItem: uuid no data in database', { uuid: item.uuid });
        this.synchronize();
        return;
      }

      const itemForUpdate: Entity = new Entity(item);
      itemForUpdate.syncState = state;

      switch (state) {
        case SYNC_ITEM_STATES.NEED_SYNCHRONIZATION:
        case SYNC_ITEM_STATES.REMOVED_NEED_SYNCHRONIZATION:
          itemForUpdate.resetRetryCounter();
          break;
        case SYNC_ITEM_STATES.STOPPED_WITH_ERROR:
        case SYNC_ITEM_STATES.SYNCHRONIZED:
          itemForUpdate.increaseRetryCounter();
          break;
      }
      itemForUpdate.setLocalLastSyncDate();

      delete itemForUpdate.localCreationDate;
      delete itemForUpdate.localModificationDate;

      await this.DbDaoService.updateItemFieldsInCollection(item.type, itemForUpdate);
      this.logger.info('updateItem uuid successfully updated with state', { type: item.type, uuid: item.uuid, state });
    } finally {
      this.synchronize();
    }
  }

  static filterItemsWithSyncError(itemsList: SyncListItem[]) {
    const normalItems = [];
    const errorItems = [];
    const retryItems = [];
    const isMoreThanOneHourAgo = (date) => moment(date).isBefore(moment().subtract(1, 'hour'));
    const maxRetryItems = 6;
    itemsList.forEach((item) => {
      const isMaxRetryCount = item.retryCount > PAYMASH_PROFILE.retrySyncMaxCount;
      if (item.retryCount < 3) {
        normalItems.push(item);
      } else if (item.retryCount <= PAYMASH_PROFILE.retrySyncCount) {
        errorItems.push(item);
      } else if (isMoreThanOneHourAgo(item.localLastSyncDate) && !isMaxRetryCount && retryItems.length < maxRetryItems) {
        retryItems.push(item);
      }
    });
    return {
      normalItems: normalItems,
      errorItems: [...errorItems, ...retryItems],
    };
  }

  private syncItemsOrdering(array: Array<SyncListItem[]>): SyncListItem[] {
    const [customers, invoices] = array;

    const filteredCustomers = SyncService.filterItemsWithSyncError(customers);
    const filteredInvoices = SyncService.filterItemsWithSyncError(invoices);

    const itemsWithError = [...filteredCustomers.errorItems, ...filteredInvoices.errorItems];

    const customersSyncList: SyncListItem[] = [...filteredCustomers.normalItems];
    const invoicesSyncList: SyncListItem[] = [...filteredInvoices.normalItems];

    invoicesSyncList.sort((a: SyncListItem, b: SyncListItem) => {
      if (a.reference.length > b.reference.length) return 1;
      if (a.reference.length < b.reference.length) return -1;
      return 0;
    });

    return [...customersSyncList, ...invoicesSyncList, ...itemsWithError];
  }

  private createGetSyncDataByTypeQuery(): Query {
    const statesForQuery = [
      SYNC_ITEM_STATES.REMOVED_NEED_SYNCHRONIZATION,
      SYNC_ITEM_STATES.NEED_SYNCHRONIZATION,
      SYNC_ITEM_STATES.IS_SYNCHRONIZING,
      SYNC_ITEM_STATES.STOPPED_WITH_ERROR,
    ];

    return { _syncState: oneOf(...statesForQuery) };
  }

  private createSelectSyncData() {
    return ['uuid', 'deleted', 'syncInProgress', 'customer', 'originalInvoiceReference', 'retryCount', 'localLastSyncDate'];
  }

  private getSyncDataByType(type: string): Observable<SyncListItem[]> {
    const query = this.createGetSyncDataByTypeQuery();
    const select = this.createSelectSyncData();
    return from(this.DbDaoService.getAllData(type, query, { select })).pipe(
      map((data) => data.data?.map((item) => new SyncListItem(item, type)) || []),
      catchError((error) => {
        this.logger.error(error, 'Catch error in getSyncDataByType', undefined, { type });
        return of([]);
      })
    );
  }

  private async handleSyncError(errorData: SyncError): Promise<void> {
    this.logger.error(errorData.error, 'handleSyncError: uuid, status', undefined, {
      uuid: errorData.syncItem.uuid,
      status: errorData.status,
    });
    if (errorData.syncItem.retryCount >= PAYMASH_PROFILE.retrySyncMaxCount) {
      this.logger.info('The type with uuid - reached to max try amount', { type: errorData.syncItem.type, uuid: errorData.syncItem.uuid });
    }
    if (errorData.status) {
      switch (errorData.status) {
        case 304:
          await this.removeDataFromSyncList(errorData.syncItem);
          break;
        case 400:
          await this.handleBadRequestError(errorData.error, errorData.syncItem);
          break;
        case 404:
          await this.removeDataFromSyncList(errorData.syncItem);
          break;
        case 500:
          await this.removeDataFromSyncListWithError(errorData.syncItem).catch((err) =>
            this.logger.error(err, 'handleSyncError:500:removeDataFromSyncListWithError')
          );
          break;
        default:
          await this.removeDataFromSyncListWithError(errorData.syncItem).catch((err) =>
            this.logger.error(err, 'handleSyncError:default:removeDataFromSyncListWithError')
          );
          break;
      }
    } else {
      await this.removeDataFromSyncListWithError(errorData.syncItem).catch((err) =>
        this.logger.error(err, 'handleSyncError:updateDataInSyncList')
      );
    }
  }

  private async handleBadRequestError(error: ServerError, syncItem: SyncListItem): Promise<void> {
    switch (error.code) {
      case SERVER_RESPONSE_ERRORS.ASSIGNED_CUSTOMER_DOES_NOT_EXIST_IN_DB:
        this.checkCustomerByInvoiceUuidInDb(syncItem.uuid)
          .then((data) => {
            if (!data.status) {
              return this.removeCustomerFromInvoice(data.invoice);
            } else {
              return this.removeDataFromSyncListWithError(syncItem).catch((err) =>
                this.logger.error(err, 'handleBadRequestError:checkCustomerByInvoiceUuidInDb:then:removeDataFromSyncListWithError')
              );
              //TODO adjust customer case handle
              // this.addDataToSyncList(new SyncListItem(data.customer, UPDATES_TYPES.Customer.type))
            }
          })
          .catch((err) => this.removeCustomerFromInvoice(err.invoice));
        break;
      // TODO: fix removing of the invoice
      case SERVER_RESPONSE_ERRORS.INVOICE_IS_ALREADY_DELETED:
        await this.removeDataFromSyncListWithError(syncItem)
          .then(() =>
            this.DbDaoService.removeDataFromCollection(syncItem.type, syncItem.uuid).catch((err) =>
              this.logger.error(err, 'handleBadRequestError:INVOICE_IS_ALREADY_DELETED:removeDataFromCollection')
            )
          )
          .catch((err) => this.logger.error(err, 'handleBadRequestError:INVOICE_IS_ALREADY_DELETED:removeDataFromSyncListWithError'));
        break;
      // TODO: fix cancelation
      case SERVER_RESPONSE_ERRORS.INVOICE_IS_ALREADY_CANCELED:
        await this.removeDataFromSyncListWithError(syncItem)
          .then(() =>
            this.DbDaoService.removeDataFromCollection(syncItem.type, syncItem.uuid).catch((err) =>
              this.logger.error(err, 'handleBadRequestError:INVOICE_IS_ALREADY_CANCELED:removeDataFromSyncListWithError')
            )
          )
          .catch((err) => this.logger.error(err, 'handleBadRequestError:INVOICE_IS_ALREADY_CANCELED:removeDataFromSyncListWithError'));
        break;
      case SERVER_RESPONSE_ERRORS.INVOICE_CAN_NOT_BE_DELETED:
        await this.InvoicesApiService.getInvoiceByUuid(syncItem.uuid)
          .then((invoice) => this.saveInvoice(invoice, true))
          .then(() => this.removeDataFromSyncList(syncItem))
          .catch((err) => {
            this.logger.error(err, 'handleBadRequestError:INVOICE_CAN_NOT_BE_DELETED:removeDataFromSyncListWithError');
            this.removeDataFromSyncList(syncItem);
          });
        break;
      case SERVER_RESPONSE_ERRORS.ORIGIN_INVOICE_NOT_EXIST:
        await this.removeDataFromSyncListWithError(syncItem).catch((err) =>
          this.logger.error(err, 'handleBadRequestError:ORIGIN_INVOICE_NOT_EXIST:removeDataFromSyncListWithError')
        );
        break;
      case SERVER_RESPONSE_ERRORS.CUSTOMER_DELETED:
        await this.removeDataFromSyncListWithError(syncItem).catch((err) =>
          this.logger.error(err, 'handleBadRequestError:CUSTOMER_DELETED:removeDataFromSyncListWithError')
        );
        break;
      case SERVER_RESPONSE_ERRORS.CUSTOMER_NOT_FOUND:
        await this.removeDataFromSyncListWithError(syncItem).catch((err) =>
          this.logger.error(err, 'handleBadRequestError:CUSTOMER_NOT_FOUND:removeDataFromSyncListWithError')
        );
        break;
      default:
        await this.removeDataFromSyncListWithError(syncItem).catch((err) =>
          this.logger.error(err, 'handleBadRequestError:default:removeDataFromSyncListWithError')
        );
        break;
    }
  }

  private removeCustomerFromInvoice(invoice: Invoice): Promise<unknown> {
    const currentCompany = this.SecurityService.getLoggedCompanyData();
    invoice.unlinkCustomerFromInvoice();
    if (currentCompany) {
      invoice.calculateInvoiceAmountAfterDiscount(currentCompany.locale.currencyRounding);
    }
    return this.saveInvoice(invoice).catch((err) => this.logger.error(err, 'removeCustomerFromInvoice:saveInvoice'));
  }

  private checkCustomerByInvoiceUuidInDb(invoiceUuid: string): Promise<{ status: boolean; invoice: Invoice; customer: Customer }> {
    return new Promise((resolve, reject) => {
      let invoice: Invoice;
      this.DbDaoService.getDataByUUID(UPDATES_TYPES.Invoice.type, invoiceUuid)
        .then((invoiceData) => {
          invoice = new Invoice(invoiceData['data']);
          return this.DbDaoService.getDataByUUID(UPDATES_TYPES.Customer.type, invoice.customer.uuid);
        })
        .then((customerData) => {
          resolve({ status: !!customerData['data'], invoice: invoice, customer: customerData.data });
        })
        .catch(() => {
          reject({ status: false, invoice: invoice, customer: null });
        });
    });
  }

  //TODO remove code duplicate
  public saveInvoice(invoice: Invoice, withoutSync?: boolean) {
    invoice.localModificationDate = moment.utc().toISOString();
    return new Promise((resolve, reject) => {
      this.DbDaoService.upsertDataToCollection(UPDATES_TYPES.Invoice.type, [invoice])
        .then((data) => {
          let newInvoiceData: Invoice = new Invoice(data['data'][0]);
          if (!withoutSync) {
            this.addDataToSyncList(new SyncListItem(newInvoiceData, UPDATES_TYPES.Invoice.type)).catch((err) =>
              this.logger.error(err, 'saveInvoice:upsertDataToCollection:then:addDataToSyncList')
            );
          }
          resolve(newInvoiceData);
        })
        .catch(reject);
    });
  }

  syncInvoicesWithErrorState() {
    const queryParams = query({
      _syncState: SYNC_ITEM_STATES.STOPPED_WITH_ERROR,
    });
    return this.DbDaoService.getAllData(UPDATES_TYPES.Invoice.type, queryParams)
      .then(async (data) => {
        for (let i = 0; i < data.data.length; i++) {
          const invoice = new Invoice(data.data[i]);
          this.logger.info('Invoice uuid was stopped with error and should be synchronized', { invoiceUuid: invoice.uuid });
          await this.saveInvoice(invoice).catch((err) => this.logger.error(err, 'updateInvoicesWithStoppedState:saveInvoice'));
        }
      })
      .catch((err) => {
        this.logger.error(err, 'updateInvoicesWithStoppedState:getDataByParams');
        throw err;
      });
  }

  private get isOffline() {
    return this.networkService.isOfflineSubject.value;
  }

  private get isSynchronizationInProgress() {
    return this.synchronizationInProgressSubject.value;
  }

  private set isSynchronizationInProgress(isInProgress) {
    this.synchronizationInProgressSubject.next(isInProgress);
  }

  public addDataToSyncList(item: SyncListItem): Promise<any> {
    const state = item.deleted ? SYNC_ITEM_STATES.REMOVED_NEED_SYNCHRONIZATION : SYNC_ITEM_STATES.NEED_SYNCHRONIZATION;
    return this.updateItem(item, state);
  }

  public removeDataFromSyncList(syncedEntity: SyncListItem) {
    return this.updateDataInSyncListAndChangeSyncItemStatus(syncedEntity, SYNC_ITEM_STATES.SYNCHRONIZED).catch((err) =>
      this.logger.error(err, 'removeDataFromSyncList:updateDataInSyncListAndChangeSyncItemStatus')
    );
  }

  private removeDataFromSyncListWithError(syncedEntity: SyncListItem): Promise<void> {
    return this.updateDataInSyncListAndChangeSyncItemStatus(syncedEntity, SYNC_ITEM_STATES.STOPPED_WITH_ERROR);
  }

  private updateDataInSyncListAndChangeSyncItemStatus(syncedEntity: SyncListItem, state: string): Promise<void> {
    return this.updateItem(syncedEntity, state)
      .then(() => {
        syncedEntity.syncInProgress = SYNC_STATUSES.NOT_IN_PROGRESS;
      })
      .catch((err) => {
        this.logger.error(err, 'updateDataInSyncListAndChangeSyncItemStatus:updateDataInSyncList', { level: ErrorLevel.Medium });
        syncedEntity.syncInProgress = SYNC_STATUSES.NOT_IN_PROGRESS;
      });
  }

  public isDataPresentInSyncList(syncState: string): boolean {
    return syncState !== SYNC_ITEM_STATES.SYNCHRONIZED;
  }

  private prepareRetryInternal(current: SyncListItem) {
    const { retrySyncItemInterval, retrySyncItemIntervalMaxValue, retrySyncCount, retrySyncMaxCount } = PAYMASH_PROFILE;
    let retryInterval = 0;
    const intervalValue = current.retryCount * retrySyncItemInterval;

    if (current.retryCount > retrySyncCount && current.retryCount <= retrySyncMaxCount) {
      retryInterval = retrySyncItemInterval;
    } else if (intervalValue >= retrySyncItemIntervalMaxValue) {
      retryInterval = retrySyncItemIntervalMaxValue;
    }

    return retryInterval;
  }
}
