import { Injectable, Injector } from '@angular/core';
import { SecurityService } from '@pos-common/services/system/security.service';
import { Company } from '@pos-common/classes/company.class';
import { Store } from '@pos-common/classes/store.class';
import { ILogger } from '@spryrocks/logger';
import { LogService } from '@pos-common/services/system/logger';
import { AlertService } from '@pos-common/services/system/alert.service';
import { ROUTE_URLS } from '@pos-common/constants/route-urls.const';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ShiftsService {
  private readonly logger: ILogger = this.logService.createLogger('ShiftsService');
  constructor(
    private logService: LogService,
    private navController: NavController,
    private injector: Injector) { }

  get isForceShiftOpeningAndClosingEnabled(): boolean {
    const securityService: SecurityService = this.injectSecurityService();
    const company: Company = securityService?.loggedCompanyData.getValue();

    if(!company) {
      this.logger.error(company, 'ShiftsService:isForceShiftOpeningAndClosingEnabled Company not exist');
    }

    const globalSyncValue: boolean = company.isCashRegisterEnabled && company?.forceShiftOpeningAndClosing;

    return globalSyncValue;

  }

  get isShiftOpenInActiveStore(): boolean {
    const securityService: SecurityService = this.injectSecurityService();
    const store: Store = securityService?.getActiveStore();

    if(!store) {
      this.logger.error(store, 'ShiftsService:isShiftOpenInActiveStore Store not exist');
    }

    return store?.isShiftOpen;
  }

  get isCashPaymentAllowed(): boolean {
    return this.isForceShiftOpeningAndClosingEnabled ? this.isShiftOpenInActiveStore : true
  }

  public async showMessageRequredOpenShift(): Promise<void> {
    const alertService: AlertService = this.injector.get(AlertService);
    const translateService: TranslateService = this.injector.get(TranslateService);

    const message: HTMLIonAlertElement = await alertService.create({
      message: translateService.instant("checkout_aside_add_payment_shift_must_open_alert_message"),
      buttons: [
        {
          text: translateService.instant('checkout_aside_add_payment_shift_must_open_alert_btn_open_page'),
          handler: () => this.redirectToCashJournalPage()
        },
        {
          text: translateService.instant('common_cancel'),
          role: 'cancel'
        }
     ]
    });
    message.present().then();
  }

  private injectSecurityService(): SecurityService {
    return this.injector.get(SecurityService);
  }

  private redirectToCashJournalPage(): void {
    this.navController
      .navigateRoot(ROUTE_URLS.collection, { animated: false })
      .catch((err) => this.logger.error(err, 'ShiftsService:navigateRoot:collection'));

    setTimeout(() => this.navController
      .navigateRoot(ROUTE_URLS.shifts, { animated: true, animationDirection: 'back' })
      .catch((err) => this.logger.error(err, 'ShiftsService:navigateRoot:shifts')), 0)

  }

}
