import { Injectable } from '@angular/core';
import { SecurityService } from '../system/security.service';
import { SERVER_CONFIG } from '../../constants/server.const';
import { Observable } from 'rxjs';
import { GiftCardsApiServiceInterface } from './gift-cards-api.service.interface.';
import { map } from 'rxjs/operators';
import { SecuredResponse } from '@pos-common/classes/secured-response.class';
import { GiftCard } from '@pos-modules/assing-gift-card/gift-card';

@Injectable()
export class GiftCardsApiService implements GiftCardsApiServiceInterface {
  readonly endpoint = 'giftCards';

  constructor(public securityService: SecurityService) {}

  getGiftCardByUuid(uuid: string): Observable<GiftCard> {
    return this.securityService
      .doSecureRequestObservable(`${SERVER_CONFIG.API_URL}${this.endpoint}/${uuid}`, 'get')
      .pipe(map((response: SecuredResponse) => new GiftCard(response?.data?.properties)));
  }

  getGiftCardByCode(code: string): Observable<GiftCard> {
    return this.securityService
      .doSecureRequestObservable(`${SERVER_CONFIG.API_URL}${this.endpoint}/byCode/${code}`, 'get')
      .pipe(map((response: SecuredResponse) => new GiftCard(response?.data?.properties)));
  }

  getGiftCardByPhysicalCode(physicalCode: string): Observable<GiftCard> {
    return this.securityService
      .doSecureRequestObservable(`${SERVER_CONFIG.API_URL}${this.endpoint}/byPhysicalCode/${physicalCode}`, 'get')
      .pipe(map((response: SecuredResponse) => new GiftCard(response?.data?.properties)));
  }
}
