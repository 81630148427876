import { Entity } from './entity.class';
import { PaymentResult } from './payment-result.class';
import { DefaultPaymentMethods } from '@pos-common/constants/default-payment-methods.enum';
import { EntityInterface } from '@pos-common/interfaces/entity.interface';
import { GiftCard } from '@pos-modules/assing-gift-card/gift-card';
import { PAYMENT_PROVIDERS } from '@pos-common/constants/payment-providers.const';
import { MathUtils } from '@pos-common/services/utils/math.utils';
import {
  getGratuityAmountFromMerchantReceipt
} from '@pos-common/services/system/adyen/services/helpers/parse-gravity.helper';
/**
 * Created by maksymkunytsia on 10/11/16.
 */

export class InvoicePayment extends Entity {
  uuid: string;
  method: string;
  amount: number;
  amountGiven: number;
  merchantReceipt: string;
  cardholderReceipt: string;
  deleted: boolean;
  amountForShow: number;
  paymentProvider: string;
  date: string;
  terminalTransaction: {};
  giftCard: EntityInterface;

  constructor(data) {
    super(data);
    this.method = data.method || null;
    this.amount = data.amount || 0;
    this.amountGiven = data.amountGiven || 0;
    this.amountForShow = data.method === DefaultPaymentMethods.CASH ? this.amountGiven : this.amount;
    this.merchantReceipt = data.merchantReceipt || null;
    this.cardholderReceipt = data.cardholderReceipt || null;
    this.deleted = data.deleted || false;
    this.paymentProvider = data.paymentProvider || null;
    this.date = data.date || null;
    this.terminalTransaction = data.terminalTransaction ? this.generateTerminalTransactionInfo(data.terminalTransaction, data?.paymentProvider, data?.merchantReceipt) : null;
    this.giftCard = data.giftCard ? new GiftCard(data.giftCard) : null;
  }

  setPaymentResultData(paymentResult: PaymentResult, isLegacyPosTippingEnabled?: boolean) {
    this.paymentProvider = paymentResult.paymentProvider || null;
    this.date = paymentResult.date || null;
    this.merchantReceipt = paymentResult.merchantReceipt || null;
    this.cardholderReceipt = paymentResult.cardholderReceipt || null;
    this.terminalTransaction = paymentResult.terminalTransaction ? this.generateTerminalTransactionInfo(paymentResult.terminalTransaction, paymentResult.paymentProvider, paymentResult.merchantReceipt) : null;
    this.uuid = paymentResult?.terminalTransaction?.paymentUuid ? paymentResult.terminalTransaction.paymentUuid : this.uuid

    if (
      isLegacyPosTippingEnabled &&
      paymentResult?.paymentProvider === PAYMENT_PROVIDERS.PAYMASH_PAY &&
      paymentResult?.terminalTransaction?.nativeTippingAmount
      ) {
        const nativeAdyenTippingAmount: number = paymentResult.terminalTransaction.nativeTippingAmount;
        this.amount = MathUtils.normalizeAddition(this.amount, nativeAdyenTippingAmount);
        this.amountForShow = MathUtils.normalizeAddition(this.amountForShow, nativeAdyenTippingAmount);
      }

  }

  private generateTerminalTransactionInfo(data: Record<string, unknown>, paymentProvider: string, receipt: string) {
    if (!paymentProvider) {
      return null;
    }

    return paymentProvider !== 'paymentPay' ? {
      ...data,
    } : {
      ...data,
      nativeTippingAmount: data?.['nativeTippingAmount'] || getGratuityAmountFromMerchantReceipt(receipt as string)?.amount
    }
  }
}
