import { Injectable } from '@angular/core';
import { ReceiptBuilder, ReceiptTextSize } from './receipt-printers/classes/receipt-builder.class';
import { ReceiptFont } from './receipt-printers/enum/printer-receipt-font.enum';
import { ReceiptTextAlign } from './receipt-printers/enum/receipt-text-align.enum';
import { SERVER_CONFIG } from '../../constants/server.const';
import { ReceiptPrinter } from './receipt-printers/classes/receipt-printer.class';
import { PaymentMethod } from '../../classes/payment-method.class';
import { INVOICE_TYPES } from '../../constants/invoice-types';
import { InvoiceEntry, SpecialTaxing } from '../../classes/invoice-entry.class';
import { Invoice } from '../../classes/invoice.class';
import { Employee } from '../../classes/employee.class';
import { Company } from '../../classes/company.class';
import { Store } from '../../classes/store.class';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationUtils } from '../utils/localization.utils';
import * as moment from 'moment';
import { SecurityService } from './security.service';
import { DayReportData, RevenueWithSales } from '../../classes/dayreport.class';
import { InvoicesService } from './invoices.service';
import { Customer } from '../../classes/customer.class';
import { LogService } from './logger/log.service';
import { ReceiptKitchenPrinterFontSize } from './receipt-printers/enum/receipt-kitchen-printer-font-size';
import {
  ReceiptWidthConfigurationInterface,
} from './receipt-printers/interfaces/receipt-printer-configuration.interface';
import { GiftCardsListInterface } from '../../../modules/assing-gift-card/gift-cards-list.interface';
import { DefaultPaymentMethods } from '@pos-common/constants/default-payment-methods.enum';
import { PRINTER_MODELS } from './receipt-printers/printer-models.const';
import { MyPosService } from './devices/my-pos/my-pos.service';
import { PrinterType } from './receipt-printers/enum/printer-type.enum';
import { KassenSichVReceipt } from '@pos-common/classes/kassenSichV-receipt.class';
import { MultipleGuestsService } from '@pos-common/services/system/multiple-guests/multiple-guests.service';
import { InvoiceEntryGuest } from '@pos-common/classes/invoice-entry-guest.class';
import { emojiPattern } from '@pos-common/constants/emoji-regex.const';
import {
  KitchenReceiptBuilder,
} from './receipt-printers/classes/kitchen-receipt-builder/kitchen-receipt-builder.class';
import { GastronomyHall } from '@pos-common/classes/gastronomy-hall.class';
import { IGroupsVirtualPrinter } from '@pos-common/interfaces';
import { DISCOUNT_CODE_TYPES, SelfOrderInvoiceFulfillmentTypesEnum, VirtualPrinterKey } from '@pos-common/constants';
import { REGEXPS } from '@pos-common/constants/regexps.const';
import { ignoreImageProps } from '@pos-common/services/system/logger';
import { RksvReceipt } from '@pos-common/classes/rksv-receipt.class';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { GiftCardProvider } from '@pos-modules/assing-gift-card/gift-card.provider';
import { SelfOrderShortOrderNumberUtil } from '@pos-common/services';

@Injectable()
export class ReceiptBuilderService {
  private readonly logger = this.logService.createLogger('ReceiptBuilderService');
  constructor(
    private securityService: SecurityService,
    private translate: TranslateService,
    private localizationUtils: LocalizationUtils,
    private myPosService: MyPosService,
    private invoicesService: InvoicesService,
    private giftCardService: GiftCardProvider,
    private multipleGuestsService: MultipleGuestsService,
    private logService: LogService
  ) {}

  /**
   * Receipts builder functions area
   * */

  public makeInvoiceReceipt(
    invoice: Invoice,
    employee: Employee,
    company: Company,
    store: Store,
    paymentMethods: PaymentMethod[],
    giftCardsList: GiftCardsListInterface,
    printer: ReceiptPrinter,
    openCashRegister?: boolean,
    cancelInvoice?: Invoice
  ): ReceiptBuilder {
    const isSelfOrderInvoice: boolean = Boolean(invoice.isSelfOrder) && Boolean(invoice.selfOrder);
    let textRowWidths = printer.rowsLength;
    employee = new Employee(employee);
    let receiptData = new ReceiptBuilder(textRowWidths);

    if (openCashRegister) {
      receiptData.addPulse();
    }

    receiptData.addFont(ReceiptFont.basic);
    receiptData.addTextSize(ReceiptTextSize.textSizeOneHeightOne);
    receiptData.addTextAlign(ReceiptTextAlign.center);
    receiptData.addNewLine();

    if (company.isQRCodeOnReceiptEnabled) {
      receiptData.addText(this.translate.instant('receipt_printer_receipt_qrcode_note'));
      receiptData.addNewLine(2);
      let qrCode = SERVER_CONFIG.PUBLIC_URL + '/I/' + invoice.publicUuid;
      if (invoice.isWebshop) {
        qrCode = SERVER_CONFIG.PUBLIC_URL + '/app#/proposals/' + invoice.uuid;
      }
      receiptData.addQrCode(qrCode);
      receiptData.addNewLine();
    }
    const companyImage = this.securityService.getCompanyLogo();
    if (companyImage) {
      receiptData.addImage(companyImage);
      receiptData.addNewLine();
    }

    // company address
    this.makeCompanyAddressBlock(receiptData, company.name, store);
    //invoice data
    this.makeStatusInvoiceBlock(receiptData, invoice, cancelInvoice);
    /* invoice general block */

    receiptData.addFont(ReceiptFont.basic);
    receiptData.addTextSize(ReceiptTextSize.textSizeOneHeightOne);

    if (invoice?.rksvReceipt?.cashRegisterSerialNo) {
      receiptData.addNewLine();
      receiptData.addTextAlign(ReceiptTextAlign.left);
      receiptData.addText(this.translate.instant('receipt_printer_rksv_cash_register_serial_no'), invoice.rksvReceipt.cashRegisterSerialNo);
    }
    // add invoice number
    receiptData.addNewLine();
    receiptData.addTextAlign(ReceiptTextAlign.left);
    receiptData.addText(this.translate.instant('receipt_printer_receipt_invoice_number'), invoice.invoiceDisplayId);
    receiptData.addNewLine();
    receiptData.addText(this.translate.instant('common_invoice_id'), invoice.uuid.substring(0, 8), textRowWidths.smallTextRowWidth);

    if (invoice?.rksvReceipt?.receiptNumber) {
      receiptData.addNewLine();
      receiptData.addTextAlign(ReceiptTextAlign.left);
      receiptData.addText(this.translate.instant('receipt_printer_rksv_receipt_number'), invoice.rksvReceipt.receiptNumber.toString());
    }
    // add invoice date
    const invoiceDate = invoice?.date ?? moment();
    receiptData.addNewLine();
    receiptData.addText(
      this.translate.instant('receipt_printer_receipt_invoice_date'),
      this.localizationUtils.getFormattedDate(invoiceDate, 'dateTimeFormat')
    );
    receiptData.addNewLine();

    if (invoice?.rksvReceipt?.signingDate) {
      receiptData.addText(
        this.translate.instant('receipt_printer_signature_date'),
        this.localizationUtils.getFormattedDate(invoice.rksvReceipt.signingDate, 'dateTimeFormat')
      );
      receiptData.addNewLine();
    }
    if (invoice.originalInvoiceReference) {
      const originalInvoice = new Invoice(invoice.originalInvoiceReference);
      receiptData.addText(this.translate.instant('common_canceled'), originalInvoice.invoiceDisplayId, textRowWidths.smallTextRowWidth);
      receiptData.addNewLine();
    }

    // add invoice employee
    if (employee.employeeDisplayName) {
      receiptData.addText(this.translate.instant('receipt_printer_receipt_invoice_operator'), employee.employeeDisplayName);
      receiptData.addNewLine();
    }
    // add invoice customer
    if (invoice.customer && invoice.customer.dataToShowInList) {
      receiptData.addText(this.translate.instant('receipt_printer_receipt_invoice_customer'), invoice.customer.dataToShowInList);
      receiptData.addNewLine();
    }
    // add invoice table
    if (invoice.gastronomyTableName) {
      receiptData.addText(this.translate.instant('restaurant_table'), invoice.gastronomyTableName);
      receiptData.addNewLine();
    }

    if (isSelfOrderInvoice && invoice?.selfOrder?.fulfillmentOptions === SelfOrderInvoiceFulfillmentTypesEnum.EAT_IN && invoice.selfOrder.table?.room) {
      receiptData.addText(this.translate.instant('room'), invoice.selfOrder.table?.room);
      receiptData.addNewLine();
    }

    if (isSelfOrderInvoice && invoice?.selfOrder?.fulfillmentOptions === SelfOrderInvoiceFulfillmentTypesEnum.EAT_IN && invoice.selfOrder.table?.table) {
      receiptData.addText(this.translate.instant('restaurant_table'), invoice.selfOrder.table?.table);
      receiptData.addNewLine();
    }

    if (isSelfOrderInvoice && invoice.selfOrder.fulfillmentOptions === SelfOrderInvoiceFulfillmentTypesEnum.EAT_IN && !(invoice?.selfOrder?.table?.table)) {
      const title: string = this.translate.instant(  'self-order.order.print.receipt.fulfillment.title');
      const body: string = this.translate.instant(  'self-order.order.print.receipt.fulfillment.eat-in.title');
      receiptData.addText(title, body);
      receiptData.addNewLine();
    }

    if (isSelfOrderInvoice && invoice.selfOrder.fulfillmentOptions === SelfOrderInvoiceFulfillmentTypesEnum.TAKE_AWAY) {
      const title: string = this.translate.instant(  'self-order.order.print.receipt.fulfillment.title');
      const body: string = this.translate.instant(  'self-order.order.print.kitchen-receipt.take-away-title');
      receiptData.addText(title, body);
      receiptData.addNewLine();
    }

    /* invoice entries (products) block */
    const isMyPosPrinter = this.myPosService.isMyPosDevice && printer.deviceType === PrinterType.MyPos;
    if (printer.deviceType === PrinterType.MyPosMini || isMyPosPrinter) {
      receiptData.addTextAlign(ReceiptTextAlign.left);
    }

    this.makeInvoiceEntriesBlock(receiptData, invoice, cancelInvoice);

    this.makeShippingCostBlock(receiptData, invoice);
    this.makeDiscountsBlock(receiptData, invoice);

    if (invoice.rounding) {
      receiptData.addText(this.translate.instant('common_rounding'), this.localizationUtils.getFormattedCurrency(invoice.rounding, false));
      receiptData.addNewLine();
    }
    /* invoice total block */
    const { currency } = company.locale;
    this.makeTotalBlock(receiptData, textRowWidths, printer, currency, invoice.amount, cancelInvoice);
    if (cancelInvoice && cancelInvoice.amount > 0) {
      this.makeTotalBlock(receiptData, textRowWidths, printer, currency, cancelInvoice.amount);
    }

    const invoicePaymentCustomerReceipts: string[] = [];
    //invoice payment info
    this.makeInvoicePaymentsBlock(receiptData, invoicePaymentCustomerReceipts, invoice, paymentMethods, currency);
    // add invoice VAT number
    if (cancelInvoice && cancelInvoice.amount > 0) {
      this.makeVatBlock(receiptData, cancelInvoice, textRowWidths.smallTextRowWidth, company.vatNumber);
    } else {
      this.makeVatBlock(receiptData, invoice, textRowWidths.smallTextRowWidth, company.vatNumber);
    }

    if (invoice.isPaid && company.isRKSVEnabled) {
      this.makeRksvReceiptBlock(receiptData, invoice.rksvReceipt);
    }

    if (invoice.kassenSichVReceipt) {
      this.makeKassenSichVReceiptBlock(receiptData, invoice.kassenSichVReceipt);
    }

    if (company.isKassenSichVEnabled && !invoice.kassenSichVReceipt && invoice.isPaid) {
      receiptData.addNewLine(2);
      receiptData.addTextAlign(ReceiptTextAlign.center);
      receiptData.addText(this.translate.instant('receipt_printer_kassenSichV_signature_is_not_available'));
    }

    if (invoicePaymentCustomerReceipts.length) {
      receiptData.addTextAlign(ReceiptTextAlign.center);
      receiptData.addBlackLineOnWholeReceiptWidth();
      invoicePaymentCustomerReceipts.forEach((paymentReceipt: string) => {
        receiptData = this.makeTerminalReceiptBlock(receiptData, paymentReceipt);
        receiptData.addBlackLineOnWholeReceiptWidth(1);
      });
    }

    if (company.receiptText) {
      receiptData.addNewLine();
      receiptData.addTextAlign(ReceiptTextAlign.center);
      receiptData.addText(company.receiptText);
    }

    receiptData.addNewLine();
    receiptData.addCut();

    const giftCardsEntriesUuidList = invoice.getGiftCardUuidFromGiftCardEntries();
    const giftCardsPaymentsUuidList = invoice.getGiftCardUuidFromGiftCardPayments();
    const giftCardsUuidList = [...giftCardsEntriesUuidList, ...giftCardsPaymentsUuidList];
    receiptData = this.makeGiftCardBlock(receiptData, giftCardsUuidList, giftCardsList);

    this.logger.debug('makeInvoiceReceipt', { receiptData: ignoreImageProps(receiptData.receiptForEpson) });
    return receiptData;
  }

  public makeDayReportReceiptDataForPrinter(data: DayReportData, printer: ReceiptPrinter, reportDate: moment.Moment): ReceiptBuilder {
    let textRowWidths = printer.rowsLength;
    let receiptData = new ReceiptBuilder(textRowWidths);
    const currentTime = moment();
    const printingDate = this.localizationUtils.getFormattedDate(currentTime, 'dateTimeFormat');
    const curDate = reportDate.date() + '. ' + moment.months()[reportDate.month()] + ' ' + reportDate.year();
    const company = this.securityService.getLoggedCompanyData();
    const activeStore = this.securityService.getActiveStore();
    this.makeCompanyAddressBlock(receiptData, company.name, activeStore);
    receiptData.addTextAlign(ReceiptTextAlign.left);
    receiptData.addFont(ReceiptFont.basic);
    receiptData.addNewLine();
    receiptData.addText(this.translate.instant('day_report_printing_date'), printingDate);
    receiptData.addNewLine(2);
    receiptData.addText(this.translate.instant('receipt_printer_receipt_daily_report'), curDate);
    const reportData = data?.properties;
    if (reportData.currentRevenue.revenue > 0) {
      receiptData.addNewLine(2);
      receiptData.addText(
        this.localizationUtils.getFormattedDate(currentTime, 'dateFormat'),
        this.localizationUtils.getRoundedFormattedCurrency(reportData.currentRevenue.revenue, false)
      );
      receiptData.addNewLine();
    }
    receiptData.addNewLine(2);

    receiptData = this.genarateDataForReportBlock(
      receiptData,
      'label.reports.productcategoryreport',
      reportData.productCategories,
      reportData.nrOfProductCategories
    );
    receiptData = this.genarateDataForReportBlock(
      receiptData,
      'label.reports.employeereport',
      reportData.employees,
      reportData.nrOfEmployees
    );
    receiptData = this.genarateDataForReportBlock(receiptData, 'label.reports.storereport', reportData.stores, reportData.nrOfStores);
    receiptData = this.genarateDataForReportBlock(receiptData, 'label.reports.paymentreport', reportData.payments, reportData.nrOfPayments);
    receiptData = this.genarateDataForReportBlock(
      receiptData,
      'label.reports.netRevenues',
      reportData.netRevenues,
      reportData.nrOfNetRevenues
    );
    receiptData = this.genarateDataForReportBlock(receiptData, 'label.reports.taxratereport', reportData.taxRates, reportData.nrOfTaxRates);
    receiptData = this.genarateDataForReportBlock(
      receiptData,
      'label.reports.taxrevenuereport',
      reportData.taxRevenues,
      reportData.nrOfTaxRevenues
    );
    receiptData = this.genarateDataForReportBlock(
      receiptData,
      'label.reports.onInvoiceReports',
      reportData.onInvoiceReports,
      reportData.nrOfOnInvoiceReports
    );
    receiptData = this.genarateDataForReportBlock(
      receiptData,
      'label.reports.discountreport',
      reportData.discounts,
      reportData.nrOfDiscounts
    );
    const giftCards = reportData.giftCards.map(
      (giftCard) => ({ ...giftCard, name: this.translate.instant(giftCard.name) } as RevenueWithSales)
    );
    receiptData = this.genarateDataForReportBlock(receiptData, 'label.reports.giftcardreport', giftCards);
    receiptData.addCut();
    return receiptData;
  }

  public makeKitchenReceipt(
    invoice: Invoice,
    employee: Employee,
    printer: ReceiptPrinter,
    guestNumber: number,
    gastronomyHall?: GastronomyHall
  ): ReceiptBuilder {
    const textRowWidths = printer.rowsLength;

    const invoiceEntries = this.invoicesService.getInvoiceEntriesForKitchen(invoice.invoiceEntries, guestNumber);
    const employeeForPrint: Employee | null = !invoice.isSelfOrder ? new Employee(employee) : null;
    let receiptData: ReceiptBuilder = null;

    const invoiceEntriesGroupsByVirtualPrinter: IGroupsVirtualPrinter = invoiceEntries.reduce((accumulator, entry) => {
      const addToArrayByKey = (key: string, item: InvoiceEntry) => {
        if (!accumulator[key]) {
          accumulator[key] = [];
        }
        accumulator[key].push(item);
      };

      if (entry.virtualPrintersUuid) {
        const virtualPrinters = entry.virtualPrintersUuid.split(',');
        virtualPrinters.forEach((virtualPrinterKey) => addToArrayByKey(virtualPrinterKey, entry));
      } else {
        let printerKey = VirtualPrinterKey.noPrinter;
        if (entry.isGuest) {
          printerKey = VirtualPrinterKey.guestPrinter;
        }
        addToArrayByKey(printerKey, entry);
      }

      return accumulator;
    }, {});

    const printersUuid = Object.keys(invoiceEntriesGroupsByVirtualPrinter)
      .filter((key) => key !== VirtualPrinterKey.guestPrinter)
      .filter((key) => key === VirtualPrinterKey.noPrinter || printer.virtualPrinters.some((virtual) => virtual.uuid === key))
      .sort((a, b) => (a === VirtualPrinterKey.noPrinter ? 1 : -1));

    let itemsExist = false;
    printersUuid.forEach((printerUuid) => {
      let invoiceEntriesForKitchen: InvoiceEntry[] = [];

      const isCurrentVirtualPrinter =
        printerUuid !== VirtualPrinterKey.noPrinter && printer.virtualPrinters.some((p) => p.uuid === printerUuid);
      if (isCurrentVirtualPrinter) {
        invoiceEntriesForKitchen = invoiceEntriesForKitchen.concat(...invoiceEntriesGroupsByVirtualPrinter[printerUuid]);
        invoiceEntriesGroupsByVirtualPrinter[printerUuid] = [];
      }

      if (invoiceEntriesGroupsByVirtualPrinter.noPrinter?.length) {
        invoiceEntriesForKitchen = invoiceEntriesForKitchen.concat(...invoiceEntriesGroupsByVirtualPrinter.noPrinter);
        invoiceEntriesGroupsByVirtualPrinter.noPrinter = [];
      }

      if (invoiceEntriesGroupsByVirtualPrinter.guestPrinter?.length) {
        invoiceEntriesForKitchen = invoiceEntriesForKitchen.reduce((accumulator: InvoiceEntry[], entry) => {
          const hasGuest = accumulator.some((value) => value.isGuest && value.guestNumber === entry.guestNumber);
          if (!hasGuest) {
            const guestEntry = invoiceEntriesGroupsByVirtualPrinter.guestPrinter.find((guest) => guest.guestNumber === entry.guestNumber);
            if (guestEntry) {
              accumulator.push(guestEntry);
            }
          }
          return [...accumulator, entry];
        }, []);
      }

      if (!invoiceEntriesForKitchen.length) {
        return;
      }

      itemsExist = true;
      const receiptPart = this.getKitchenReceiptBlock(
        textRowWidths,
        invoice,
        employeeForPrint,
        invoiceEntriesForKitchen,
        printer,
        gastronomyHall
      );
      if (!receiptData) {
        receiptData = receiptPart;
      } else {
        receiptData.concatReceiptBuilder(receiptPart);
      }
    });

    if (!itemsExist) {
      return null;
    }

    return receiptData;
  }

  private makeStatusInvoiceBlock(builder: ReceiptBuilder, invoice: Invoice, cancelInvoice: Invoice) {
    const hasStatusBlock = invoice.isPrinted || invoice.invoiceType === INVOICE_TYPES.CANCELLATION || !!cancelInvoice;
    if (!hasStatusBlock) {
      return;
    }

    builder.addFont(ReceiptFont.condensed);
    builder.addTextSize(ReceiptTextSize.textSizeTwoHeightTwo);
    builder.addTextAlign(ReceiptTextAlign.center);
    if (invoice.isPrinted) {
      builder.addNewLine();
      builder.addText(this.translate.instant('common_copy'));
    }
    if (invoice.invoiceType === INVOICE_TYPES.CANCELLATION) {
      builder.addNewLine();
      builder.addText(this.translate.instant('common_cancellation'));
    } else if (cancelInvoice) {
      const { amount } = cancelInvoice;
      let translateText = amount === 0 ? 'common_cancelled' : '';
      translateText = amount > 0 ? 'common_partially_cancelled' : translateText;
      if (translateText) {
        builder.addNewLine();
        builder.addText(this.translate.instant(translateText));
      }
    }
    builder.addNewLine();
  }

  public makeCustomerNoteDataForPrinter(customerData: Customer, employee: Employee, printer: ReceiptPrinter): ReceiptBuilder {
    let textRowWidths = printer.rowsLength;
    employee = new Employee(employee);
    let customerDataForPrinter: ReceiptBuilder = new ReceiptBuilder(textRowWidths);
    customerDataForPrinter.addTextAlign(ReceiptTextAlign.left);
    customerDataForPrinter.addFont(ReceiptFont.basic);
    customerDataForPrinter.addNewLine(3);
    customerDataForPrinter.addText(
      this.translate.instant('receipt_printer_receipt_invoice_date'),
      this.localizationUtils.getFormattedDate(moment(), 'dateFormat')
    );
    customerDataForPrinter.addNewLine();
    if (employee.employeeDisplayName) {
      customerDataForPrinter.addText(this.translate.instant('common_employee'), employee.employeeDisplayName);
      customerDataForPrinter.addNewLine();
    }
    customerDataForPrinter.addUnderLine();
    customerDataForPrinter.addNewLine(2);
    customerDataForPrinter.addFont(ReceiptFont.condensed);
    customerDataForPrinter.addTextSize(ReceiptTextSize.textSizeTwoHeightTwo);
    customerDataForPrinter.addText(customerData.dataToShowInList || this.translate.instant('customers_unknown'));
    customerDataForPrinter.addNewLine();
    customerDataForPrinter.addTextSize(ReceiptTextSize.textSizeOneHeightOne);

    customerDataForPrinter.addTextAlign(ReceiptTextAlign.left);
    customerDataForPrinter.addFont(ReceiptFont.basic);
    customerDataForPrinter.addUnderLine();
    customerDataForPrinter.addNewLine(2);
    customerDataForPrinter.addText(customerData.note);
    customerDataForPrinter.addNewLine();
    customerDataForPrinter.addUnderLine();
    customerDataForPrinter.addNewLine(3);
    customerDataForPrinter.addCut();
    return customerDataForPrinter;
  }

  public makeTerminalReceipt(receipt: string) {
    let receiptData = new ReceiptBuilder();

    if (receipt) {
      receiptData = this.makeTerminalReceiptBlock(receiptData, receipt);
      receiptData.addCut();
    }
    return receiptData;
  }

  /**
   * Private functions area
   * */

  private getKitchenReceiptBlock(
    textRowWidths: ReceiptWidthConfigurationInterface,
    invoice: Invoice,
    employee: Employee | null,
    invoiceEntriesForKitchen: InvoiceEntry[],
    printer: ReceiptPrinter,
    gastronomyHall?: GastronomyHall
  ): ReceiptBuilder {
    const receiptData = new KitchenReceiptBuilder(textRowWidths);
    const isSelfOrderInvoice: boolean = Boolean(invoice.isSelfOrder) && Boolean(invoice.selfOrder);
    const isMyPosPrinter = printer.deviceType === PrinterType.MyPos && this.myPosService.isMyPosDevice;
    const isMyPos = printer.deviceType === PrinterType.MyPosMini || isMyPosPrinter;

    receiptData.addTextAlign(ReceiptTextAlign.left);

    if (isSelfOrderInvoice && invoice.invoiceId) {
      receiptData.setKitchenPrinterFontSize(ReceiptKitchenPrinterFontSize.BIG, isMyPos);
      const shortNumberOrder: string = SelfOrderShortOrderNumberUtil(invoice.invoiceId);
      const titleOrder: string = receiptData.lineWidth < 20
        ? 'self-order.order.print.kitchen-receipt.self-order-number-short-title'
        : 'self-order.order.print.kitchen-receipt.self-order-number-title';
      receiptData.addText(this.translate.instant(titleOrder), shortNumberOrder);
      receiptData.addNewLine();
    }

    const receiptKitchenPrinterFontSize: ReceiptKitchenPrinterFontSize = isSelfOrderInvoice ? ReceiptKitchenPrinterFontSize.SMALL : ReceiptKitchenPrinterFontSize.BIG;
    receiptData.setKitchenPrinterFontSize(receiptKitchenPrinterFontSize, isMyPos);
    // add invoice number
    let invoiceNumberText = 'receipt_printer_receipt_invoice_number';
    if (receiptData.lineWidth < 20) {
      invoiceNumberText = 'receipt_printer_receipt_short_invoice_number';
    }
    receiptData.addText(this.translate.instant(invoiceNumberText), invoice.invoiceId);
    receiptData.addNewLine();

    receiptData.setKitchenPrinterFontSize(ReceiptKitchenPrinterFontSize.SMALL, isMyPos);
    const invoiceDate = invoice?.date || moment();
    receiptData.addText(
      this.translate.instant('receipt_printer_receipt_invoice_date'),
      this.localizationUtils.getFormattedDate(invoiceDate, 'dateTimeListFormat')
    );
    receiptData.addNewLine();
    // add invoice employee
    if (employee?.employeeDisplayName) {
      receiptData.addText(this.translate.instant('receipt_printer_receipt_invoice_operator'), employee.employeeDisplayName);
      receiptData.addNewLine();
    }
    // add invoice customer
    if (invoice.customer && invoice.customer.dataToShowInList) {
      receiptData.addText(this.translate.instant('receipt_printer_receipt_invoice_customer'), invoice.customer.dataToShowInList);
      receiptData.addNewLine();
    }

    receiptData.setKitchenPrinterFontSize(ReceiptKitchenPrinterFontSize.BIG, isMyPos);
    if (gastronomyHall) {
      receiptData.addText(this.translate.instant('room'), gastronomyHall.name);
      receiptData.addNewLine();
    }

    if (isSelfOrderInvoice && invoice.selfOrder.fulfillmentOptions === SelfOrderInvoiceFulfillmentTypesEnum.EAT_IN && invoice.selfOrder.table?.room) {
      receiptData.addText(this.translate.instant('room'), invoice.selfOrder.table?.room);
      receiptData.addNewLine();
    }

    if (invoice.gastronomyTableName) {
      receiptData.addText(this.translate.instant('restaurant_table'), invoice.gastronomyTableName);
      receiptData.addNewLine();
    }

    if (isSelfOrderInvoice && invoice.selfOrder.fulfillmentOptions === SelfOrderInvoiceFulfillmentTypesEnum.EAT_IN && invoice?.selfOrder?.table?.table) {
      receiptData.addText(this.translate.instant('restaurant_table'), invoice.selfOrder.table?.table);
      receiptData.addNewLine();
    }

    if (isSelfOrderInvoice && invoice.selfOrder.fulfillmentOptions === SelfOrderInvoiceFulfillmentTypesEnum.EAT_IN && !(invoice?.selfOrder?.table?.table)) {
      receiptData.addText(this.translate.instant('self-order.order.print.kitchen-receipt.eat-in.no-table'));
      receiptData.addNewLine();
    }

    if (isSelfOrderInvoice && invoice.selfOrder.fulfillmentOptions === SelfOrderInvoiceFulfillmentTypesEnum.TAKE_AWAY) {
      receiptData.addText(this.translate.instant('self-order.order.print.kitchen-receipt.take-away-title'));
      receiptData.addNewLine();
    }

    receiptData.setKitchenPrinterFontSize(ReceiptKitchenPrinterFontSize.BIG, isMyPos);
    if (isSelfOrderInvoice && invoice.selfOrder?.comment && invoice.selfOrder.comment.length > 0) {
      const commentTitle: string = this.translate.instant('self-order.order.print.kitchen-receipt.customer-comment-title');
      receiptData.addBlackLineOnWholeReceiptWidth();
      receiptData.addText(commentTitle + invoice.selfOrder.comment);
      receiptData.addNewLine();
    }

    /* invoice entries (products) block */
    if (invoiceEntriesForKitchen.length > 0) {
      const invoiceEntryGuests = this.multipleGuestsService.getInvoiceEntryGuests(invoiceEntriesForKitchen);
      const invoiceEntriesOrGuests = this.multipleGuestsService.getInvoiceEntryOrGuestList(invoiceEntryGuests, invoiceEntriesForKitchen);

      receiptData.setKitchenPrinterFontSize(ReceiptKitchenPrinterFontSize.SMALL, isMyPos);
      receiptData.addBlackLineOnWholeReceiptWidth(1);
      if (!invoiceEntryGuests.length) {
        receiptData.addNewLine();
      }

      receiptData.setKitchenPrinterFontSize(ReceiptKitchenPrinterFontSize.BIG, isMyPos);
      // process invoice entries
      for (let i = 0; i < invoiceEntriesOrGuests.length; i++) {
        let current = invoiceEntriesOrGuests[i];
        if (current instanceof InvoiceEntryGuest) {
          if (current.invoiceEntryCount) {
            this.makeInvoiceEntryGuestLine(receiptData, current.name);
          }
          continue;
        }
        current = current as InvoiceEntry;
        const quantityString = `${current.quantityForKitchenReceipt} x `;
        receiptData.addText(quantityString + this.deleteEmojiFromText(current.name));
        receiptData.addNewLine();
        if (current.note) {
          const noteText = ' '.repeat(current.quantityForKitchenReceipt.toString().length);
          receiptData.addText(`${noteText} >>` + current.note);
          receiptData.addNewLine();
        }
      }
    }
    receiptData.setKitchenPrinterFontSize(ReceiptKitchenPrinterFontSize.SMALL, isMyPos);
    receiptData.addBlackLineOnWholeReceiptWidth();
    receiptData.addCut();
    return receiptData as ReceiptBuilder;
  }

  private makeGiftCardBlock(builder: ReceiptBuilder, giftCardsUuidList: string[], giftCardsList: GiftCardsListInterface): ReceiptBuilder {
    try {
      giftCardsUuidList.forEach((uuid) => {
        const giftCard = giftCardsList[uuid];
        if (!giftCard) {
          return;
        }
        const { textSizeOneHeightOne, textSizeTwoHeightTwo } = ReceiptTextSize;
        const { code, balance } = giftCard;
        builder.addNewLine();
        builder.addTextAlign(ReceiptTextAlign.center);
        builder.addQrCode(code, 12);
        builder.addNewLine();

        builder.addTextAlign(ReceiptTextAlign.center);
        const receiptTextSize = builder.bigTextRowWidth < 20 ? textSizeOneHeightOne : textSizeTwoHeightTwo;
        builder.addTextSize(receiptTextSize);
        builder.addText(code);
        builder.addTextSize(textSizeOneHeightOne);
        builder.addNewLine(2);

        builder.addText(this.translate.instant('giftcard_balance'), this.localizationUtils.getRoundedFormattedCurrency(balance, true));
        builder.addNewLine(2);

        builder.addCut();
      });
    } catch (error) {
      this.logger.error(error, 'makeGiftCardBlock');
    }
    return builder;
  }

  private getVatBlockString(
    vatBlock: Array<Array<string>>,
    maxLength: number,
    vatBlockParams: { maxColumnWidth: number[]; fullStringWidth: number }
  ): string {
    let fullString: string = '';
    for (let i = 0; i < vatBlock.length; i++) {
      fullString += this.makeStringWithColumns(vatBlock[i], maxLength, vatBlockParams);
    }
    return fullString;
  }

  private makeStringWithColumns(
    words: string[],
    maxLength: number,
    columnParams: { maxColumnWidth: number[]; fullStringWidth: number }
  ): string {
    const deviderCount = words.length - 1;
    let deviderLength = Math.round((maxLength - columnParams.fullStringWidth) / deviderCount);
    if (deviderLength <= 0) {
      deviderLength = 1;
    }
    let resultString: string = '';
    for (let i = 0; i < words.length; i++) {
      if (i === 0) {
        resultString +=
          words[i] +
          ' '.repeat(columnParams.maxColumnWidth[i] - words[i].length > 0 ? columnParams.maxColumnWidth[i] - words[i].length : 0);
      } else if (i === words.length - 1) {
        let stringToAdd: string =
          ' '.repeat(columnParams.maxColumnWidth[i] - words[i].length >= 0 ? columnParams.maxColumnWidth[i] - words[i].length : 0) +
          words[i];
        let devider: number = maxLength - (resultString.length + stringToAdd.length);
        if (devider <= 0) devider = 1;
        resultString += ' '.repeat(devider) + stringToAdd;
      } else {
        resultString +=
          ' '.repeat(deviderLength) +
          ' '.repeat(columnParams.maxColumnWidth[i] - words[i].length > 0 ? columnParams.maxColumnWidth[i] - words[i].length : 0) +
          words[i];
      }
    }
    return resultString + '\n';
  }

  private getVatBlockColumnParams(vatBlock: Array<Array<string>>) {
    const maxColumnWidth: number[] = [];
    let fullStringWidth: number = 0;
    for (let i = 0; i < vatBlock.length; i++) {
      const vatLine = vatBlock[i];
      for (let j = 0; j < vatLine.length; j++) {
        if (!maxColumnWidth[j]) maxColumnWidth[j] = 0;
        const vat = vatLine[j];
        const vatLength: number = vat.length;
        if (maxColumnWidth[j] < vatLength) {
          maxColumnWidth[j] = vatLength;
        }
      }
    }
    maxColumnWidth.forEach((item) => (fullStringWidth += item));
    return { maxColumnWidth: maxColumnWidth, fullStringWidth: fullStringWidth };
  }

  private genarateDataForReportBlock(
    receiptData: ReceiptBuilder,
    blockNameForTranslation: string,
    blockData: any[],
    blockDataCount?: number
  ): ReceiptBuilder {
    if (blockDataCount === 0 || !blockData.length) return receiptData;
    let blockCount = '';
    if (blockDataCount) {
      blockCount = `(${blockDataCount})`;
    }
    receiptData.addText(this.translate.instant(blockNameForTranslation), blockCount);
    receiptData.addNewLine();
    receiptData.addUnderLine('.');
    receiptData.addNewLine();
    for (let cat of blockData) {
      receiptData.addText(cat.name, this.localizationUtils.getRoundedFormattedCurrency(cat.revenue, false));
      receiptData.addNewLine();
    }
    receiptData.addNewLine();

    return receiptData;
  }

  private makeInvoiceEntriesBlock(builder: ReceiptBuilder, invoice: Invoice, cancelInvoice: Invoice) {
    try {
      const invoiceEntries = invoice.getActiveInvoiceEntriesWithoutTypes(PRODUCT_TYPES.SHIPPING_COST);
      const isSelfOrder: boolean = invoice?.isSelfOrder && Boolean(invoice?.selfOrder);
      if (invoiceEntries.length > 0) {
        builder.addFont(ReceiptFont.basic);
        const invoiceEntryGuests = this.multipleGuestsService.getInvoiceEntryGuests(invoiceEntries);
        const invoiceEntriesOrGuests = this.multipleGuestsService.getInvoiceEntryOrGuestList(invoiceEntryGuests, invoiceEntries);

        builder.addBlackLineOnWholeReceiptWidth(1);
        if (!invoiceEntryGuests.length) {
          builder.addNewLine();
        }
        let isPartialCancellation = false;
        for (let i = 0; i < invoiceEntriesOrGuests.length; i++) {
          let current = invoiceEntriesOrGuests[i];
          if (current instanceof InvoiceEntryGuest) {
            if (current.invoiceEntryCount) {
              this.makeInvoiceEntryGuestLine(builder, current.name);
            }
            continue;
          }
          current = current as InvoiceEntry;
          let cancelInvoiceEntry: InvoiceEntry = null;
          if (cancelInvoice) {
            const currentCartEntryIndex = this.invoicesService.getEntryByUuidAndCategory(
              current.type,
              current,
              cancelInvoice.invoiceEntries
            );
            if (currentCartEntryIndex.length > 0) {
              cancelInvoiceEntry = cancelInvoice.invoiceEntries[currentCartEntryIndex[0]];
            }
            isPartialCancellation = this.isPartialInvoiceEntryCancallation(cancelInvoice.amount, current, cancelInvoiceEntry);
          }
          if (i > 0 && isPartialCancellation) {
            builder.addNewLine();
          }
          this.makeOneInvoiceEntryBlock(builder, current, isPartialCancellation, isSelfOrder);
          if (isPartialCancellation && cancelInvoiceEntry.quantity > 0) {
            this.makeOneInvoiceEntryBlock(builder, cancelInvoiceEntry, false, isSelfOrder);
          }
        }
      }
      builder.addBlackLineOnWholeReceiptWidth();
    } catch (error) {
      this.logger.error(error, 'makeInvoiceEntriesBlock');
    }
  }

  private makeDiscountsBlock(builder: ReceiptBuilder, invoice: Invoice) {
    const { totalInvoiceDiscountAmount, totalCustomerDiscountAmount, totalAmountWithoutDiscount, discountCodeDiscount } = invoice;
    if (totalInvoiceDiscountAmount > 0 || totalCustomerDiscountAmount > 0 || discountCodeDiscount > 0) {
      builder.addText(
        this.translate.instant('common_subtotal') + ' ',
        this.localizationUtils.getFormattedCurrency(totalAmountWithoutDiscount, false)
      );
      builder.addNewLine();
    }
    if (totalCustomerDiscountAmount > 0) {
      const { customerDiscountPercentage } = invoice;
      const discountAnotation = customerDiscountPercentage > 0 ? ` ${customerDiscountPercentage}%` : '';
      builder.addText(
        `${this.translate.instant('customer_discount')}${discountAnotation}`,
        this.localizationUtils.getFormattedCurrency(totalCustomerDiscountAmount, false)
      );
      builder.addNewLine();
    }
    if (totalInvoiceDiscountAmount > 0) {
      const { discountPercentage } = invoice;
      const discountAnotation = discountPercentage > 0 ? ` ${discountPercentage}%` : '';
      builder.addText(
        `${this.translate.instant('invoice_discount')}${discountAnotation}`,
        this.localizationUtils.getFormattedCurrency(totalInvoiceDiscountAmount, false)
      );
      builder.addNewLine();
    }
    if (discountCodeDiscount > 0) {
      const { discountCode } = invoice;
      const discountAnotation = discountCode.type === DISCOUNT_CODE_TYPES.PERCENTAGE ? ` ${discountCode.value}%` : '';
      builder.addText(
        `${this.translate.instant('code_discount')} (${discountCode.code}${discountAnotation})`,
        this.localizationUtils.getFormattedCurrency(discountCodeDiscount, false)
      );
      builder.addNewLine();
    }
  }

  private deleteEmojiFromText(text: string): string {
    if (!text) {
      return text;
    }
    return text.replace(emojiPattern, '');
  }

  private isPartialInvoiceEntryCancallation(
    cancelInvoiceAmount: number,
    invoiceEntry: InvoiceEntry,
    cancelInvoiceEntry: InvoiceEntry
  ): boolean {
    return (
      cancelInvoiceAmount > 0 &&
      cancelInvoiceEntry &&
      (cancelInvoiceEntry.quantity === 0 || cancelInvoiceEntry.quantity < invoiceEntry.quantity)
    );
  }

  private makeOneInvoiceEntryBlock(builder: ReceiptBuilder, invoiceEntry: InvoiceEntry, isPartialCancellation: boolean = false, isSelfOrder: boolean = false) {
    let name = this.deleteEmojiFromText(invoiceEntry.name);
    if (isPartialCancellation) {
      name = `${this.getCancelledText()} ${name}`;
    }
    builder.addTextBetweenSquareBrackets(
      isPartialCancellation,
      name,
      this.localizationUtils.getFormattedCurrency(invoiceEntry.totalAmount, false)
    );
    builder.addNewLine();
    if (invoiceEntry.specialTaxing === SpecialTaxing.takeAway && !isSelfOrder) {
      builder.addTextBetweenSquareBrackets(isPartialCancellation, this.translate.instant('take_away'));
      builder.addNewLine();
    }
    if (invoiceEntry.isSale) {
      const wasPrice = this.localizationUtils.getFormattedCurrency(invoiceEntry.wasPrice, false);
      const priceBeforeReduction = `(${this.translate.instant('price_before_reduction')} ${wasPrice})`;
      builder.addTextBetweenSquareBrackets(isPartialCancellation, priceBeforeReduction);
      builder.addNewLine();
    }
    if (invoiceEntry.note) {
      builder.addTextBetweenSquareBrackets(isPartialCancellation, invoiceEntry.note);
      builder.addNewLine();
    }
    if (invoiceEntry.giftCardCode) {
      const giftCardCode = this.giftCardService.makeCardObfuscation(invoiceEntry.giftCardCode);
      builder.addTextBetweenSquareBrackets(false, giftCardCode);
      builder.addNewLine();
    }
    if (invoiceEntry.quantity > 1 || invoiceEntry.discount > 0 || invoiceEntry.discountPercentage > 0) {
      let quantityString = `  ${invoiceEntry.quantity} x ${this.localizationUtils.getFormattedCurrency(invoiceEntry?.price, false)}`;
      if (invoiceEntry.discount > 0) {
        quantityString += `, ${this.translate.instant('common_discount')} ${this.localizationUtils.getFormattedCurrency(
          invoiceEntry.discount,
          false
        )}`;
      }
      if (invoiceEntry.discountPercentage > 0) {
        quantityString += `, ${this.translate.instant('common_discount')} ${invoiceEntry.discountPercentage}%`;
      }
      builder.addTextBetweenSquareBrackets(isPartialCancellation, quantityString, ' ');
      builder.addNewLine();
    }
  }

  private getCancelledText() {
    return `***${this.translate.instant('common_cancelled').toUpperCase()}***`;
  }

  private makeTotalBlock(
    builder: ReceiptBuilder,
    textRowWidths: ReceiptWidthConfigurationInterface,
    printer: ReceiptPrinter,
    currency: string,
    invoiceAmount: number,
    cancelInvoice?: Invoice
  ) {
    try {
      const { printerModel, deviceType } = printer;
      let isPartialCancellation = false;
      if (cancelInvoice && cancelInvoice.amount > 0) {
        isPartialCancellation = true;
        builder.addTextBetweenSquareBrackets(isPartialCancellation, this.getCancelledText());
        builder.addNewLine();
      }
      let totalStringLength = textRowWidths.bigTextRowWidth;
      if (printerModel === PRINTER_MODELS.TM_P20.model || printerModel === PRINTER_MODELS.TM_U220.model) {
        builder.addFont(ReceiptFont.condensed);
        totalStringLength = textRowWidths.kitchenBigTextRowWidth;
      }
      builder.addTextSize(ReceiptTextSize.textSizeTwoHeightTwo);
      builder.addTextAlign(ReceiptTextAlign.right);
      const isMyPosPrinter = deviceType === PrinterType.MyPos && this.myPosService.isMyPosDevice;
      if (deviceType === PrinterType.MyPosMini || isMyPosPrinter) {
        builder.addFont(ReceiptFont.condensed);
        builder.addTextAlign(ReceiptTextAlign.left);
      }

      const totalLeftText = `${this.translate.instant('receipt_printer_receipt_total')} ${currency.toUpperCase()}`;
      builder.addTextBetweenSquareBrackets(
        isPartialCancellation,
        totalLeftText,
        this.localizationUtils.getFormattedCurrency(invoiceAmount, false),
        totalStringLength
      );
      builder.addNewLine(2);
      builder.addTextSize(ReceiptTextSize.textSizeOneHeightOne);
      builder.addFont(ReceiptFont.basic);
      builder.addTextAlign(ReceiptTextAlign.left);
    } catch (error) {
      this.logger.error(error, 'makeTotalBlock');
    }
  }

  private makeInvoicePaymentsBlock(
    builder: ReceiptBuilder,
    invoicePaymentCustomerReceipts: string[],
    invoice: Invoice,
    paymentMethods: PaymentMethod[],
    currency: string
  ) {
    try {
      if (invoice.invoicePayments && invoice.invoicePayments.length > 0) {
        const invoicePayments = invoice.invoicePayments.filter((invoicePayment) => !invoicePayment.deleted);
        for (let i = 0; i < invoicePayments.length; i++) {
          const currentPayment = invoicePayments[i];
          const paymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.method === currentPayment.method);
          let paymentMethodName: string = paymentMethod
            ? this.translate.instant(paymentMethod.name)
            : this.translate.instant(currentPayment.method);
          paymentMethodName = paymentMethodName.charAt(0).toUpperCase() + paymentMethodName.slice(1);
          const amountForShow =
            invoice.isCancelled && currentPayment.method === DefaultPaymentMethods.CASH
              ? currentPayment.amount
              : currentPayment.amountForShow;
          builder.addText(
            `${paymentMethodName} ${currency.toUpperCase()}`,
            this.localizationUtils.getFormattedCurrency(amountForShow, false)
          );
          builder.addNewLine();
          if (currentPayment.cardholderReceipt) {
            invoicePaymentCustomerReceipts.push(currentPayment.cardholderReceipt);
          }
        }

        const change = invoice.getAmountOfChange();
        if (change) {
          builder.addText(
            `${this.translate.instant('receipt_printer_receipt_change')} ${currency.toUpperCase()}`,
            this.localizationUtils.getFormattedCurrency(change, false)
          );
          builder.addNewLine();
        }
      }
    } catch (error) {
      this.logger.error(error, 'makeInvoicePaymentsBlock');
    }
  }

  private makeVatBlock(builder: ReceiptBuilder, invoice: Invoice, smallTextRowWidth: number, vatNumber: string) {
    try {
      if (vatNumber) {
        const vat = `${this.translate.instant('receipt_printer_receipt_vat_number')} ${vatNumber}`;
        builder.addNewLine();
        builder.addText(vat);
      }
      const vatBlock: Array<string[]> = [];
      // add invoice VAT
      const vatBlockHeader = [
        this.translate.instant('vat_rate'),
        this.translate.instant('vat_net'),
        this.translate.instant('common_taxes'),
        this.translate.instant('vat_gross'),
      ];
      vatBlock.push(vatBlockHeader);
      builder.addNewLine();

      const vatTotal = {
        netTotal: 0,
        vatTotal: 0,
        grossTotal: 0,
      };
      const invoiceTaxes = this.invoicesService.getTaxes(invoice);
      for (const invoiceTax of invoiceTaxes) {
        vatTotal.netTotal += invoiceTax.netAmount;
        vatTotal.vatTotal += invoiceTax.totalTaxAmount;
        vatTotal.grossTotal += invoiceTax.totalAmount;
        const vatBlockValue = [
          `${this.localizationUtils.getFormattedCurrency(invoiceTax.taxRate * 100, false)}%`,
          this.localizationUtils.getFormattedCurrency(invoiceTax.netAmount, false),
          this.localizationUtils.getFormattedCurrency(invoiceTax.totalTaxAmount, false),
          this.localizationUtils.getFormattedCurrency(invoiceTax.totalAmount, false),
        ];
        vatBlock.push(vatBlockValue);
      }
      if (invoiceTaxes.length > 1) {
        const vatBlockSum = [
          this.translate.instant('vat_sum'),
          this.localizationUtils.getFormattedCurrency(vatTotal.netTotal, false),
          this.localizationUtils.getFormattedCurrency(vatTotal.vatTotal, false),
          this.localizationUtils.getFormattedCurrency(vatTotal.grossTotal, false),
        ];
        vatBlock.push(vatBlockSum);
      }

      builder.addText(this.getVatBlockString(vatBlock, smallTextRowWidth, this.getVatBlockColumnParams(vatBlock)));
    } catch (error) {
      this.logger.error(error, 'makeVatBlock');
    }
  }

  private makeKassenSichVReceiptBlock(builder: ReceiptBuilder, kassenSichVReceipt: KassenSichVReceipt) {
    if (!kassenSichVReceipt.qrCode) {
      return;
    }
    builder.addUnderLine();
    builder.addNewLine(2);
    builder.addFont(ReceiptFont.basic);

    builder.addTextAlign(ReceiptTextAlign.center);
    builder.addText(this.translate.instant('receipt_printer_kassenSichV_data'));
    builder.addNewLine(2);
    builder.addQrCode(kassenSichVReceipt.qrCode, 4);
    builder.addNewLine();
  }

  private makeRksvReceiptBlock(builder: ReceiptBuilder, rksvReceipt: RksvReceipt) {
    const receiptRksvNotAvailable = this.translate.instant('receipt_printer_rksv_signature_is_not_available');
    const qrCode = rksvReceipt?.qr || receiptRksvNotAvailable;
    builder.addUnderLine();
    builder.addNewLine(2);
    builder.addFont(ReceiptFont.basic);
    builder.addTextAlign(ReceiptTextAlign.center);
    builder.addText(this.translate.instant('receipt_printer_rksv_data'));
    builder.addNewLine(2);
    builder.addQrCode(qrCode, 4);
    if (!rksvReceipt?.qr) {
      builder.addNewLine(2);
      builder.addText(receiptRksvNotAvailable);
    }
  }

  private makeInvoiceEntryGuestLine(builder: ReceiptBuilder, name: string) {
    builder.addNewLine();
    builder.addText(`--${name}--`);
    builder.addNewLine();
  }

  private makeTerminalReceiptBlock(builder: ReceiptBuilder, receipt: string) {
    const receiptLines = receipt.replace(REGEXPS.TWO_LAST_ENTERS, '').replace(REGEXPS.TWO_ENTERS, '\n').split('\n');
    const allLength = receiptLines.map((text) => text.length);
    const maxLength = Math.max(...allLength, 0);

    receiptLines.forEach((text) => {
      const isCenter = text.match(REGEXPS.TWO_START_SPACES) && text.length < maxLength;
      const isCenterText = text.match(REGEXPS.TWO_START_SPACES) && text.match(REGEXPS.TWO_END_SPACES);
      if (isCenter || isCenterText) {
        text = text ? text.trim() : text;
        builder.addTextAlign(ReceiptTextAlign.center);
      } else {
        builder.addTextAlign(ReceiptTextAlign.left);
      }

      if (REGEXPS.ONLY_DASH.test(text)) {
        builder.addUnderLine('-');
        builder.addNewLine();
        return;
      }

      let left = text;
      let right = '';
      const multiText = text.split(REGEXPS.MORE_THREE_SPACES);
      if (multiText.length === 2) {
        left = multiText[0];
        right = multiText[1];
      } else if (multiText.length === 3) {
        left = `${multiText[0]}  ${multiText[1]}`;
        right = multiText[2];
      }

      builder.addText(left, right);
      builder.addNewLine();
    });
    return builder;
  }

  private makeCompanyAddressBlock(builder: ReceiptBuilder, companyName: string, store: Store) {
    builder.addTextAlign(ReceiptTextAlign.center);
    builder.addFont(ReceiptFont.basic);
    builder.addText(companyName);
    if (store.name !== companyName) {
      builder.addNewLine();
      builder.addText(this.translate.instant('receipt_printer_receipt_store') + ' ' + store.name);
    }
    if (!store.address) {
      return;
    }
    const { address } = store;
    if (address?.address) {
      builder.addNewLine();
      builder.addText(address.address);
    }
    let addressString: string = address?.postalCode ? address.postalCode : '';
    addressString += address?.city ? ' ' + address.city : '';
    if (addressString) {
      builder.addNewLine();
      builder.addText(addressString);
    }
  }

  private makeShippingCostBlock(builder: ReceiptBuilder, invoice: Invoice) {
    const shippingCost = invoice.getShippingCostInvoiceEntry();
    if (shippingCost) {
      this.makeOneInvoiceEntryBlock(builder, shippingCost, false, false);
    }
  }
}
