import { Injectable } from '@angular/core';
import { ErrorLevel, ILoggerFactory, LoggerFactory as CoreLoggerFactory, LoggerObserver, LogLevel } from '@spryrocks/logger';
import {
  CapacitorPluginLogReceiver,
  ConsoleReporter,
  CoreLogger,
  CoreLogReceiver,
  ElasticReporterNative,
  ElasticReporterWeb,
  LogFilter,
  SentryErrorReporter,
} from '@paymash/logger-capacitor';
import { PAYMASH_PROFILE } from '@profile';
import { CoreFields } from './core-fields.enum';
import { CapacitorPlugin } from '@spryrocks/capacitor-ionic-core-plugin';
import { LoggerObserver as SocketConnectionPluginLoggerObserver } from '@spryrocks/capacitor-socket-connection-plugin';
import { ElasticLogger } from './elastic-logger';
import * as Sentry from '@sentry/capacitor';
import { PlatformService } from '@pos-common/services/system/platform/platform.service';
import { FileSystemPlugin, SystemDirectoryType } from '@spryrocks/capacitor-filesystem-plugin';

const observer = new LoggerObserver();
const factory = new CoreLoggerFactory({
  notifier: observer,
  prepareLogData: ({ data }) => data,
  globalData: undefined,
});

@Injectable()
export class LogService implements ILoggerFactory {
  private readonly core: CoreLogger<CoreFields>;

  constructor(private readonly elasticLogger: ElasticLogger, private readonly platformService: PlatformService) {
    this.core = new CoreLogger();
    this.platformService.ready().then(() => {
      this.registerReporters();
      observer.add(new CoreLogReceiver(this.core));
      this.registerPlugins();
    });
  }

  private registerReporters() {
    if (!PAYMASH_PROFILE.isLoggerActive) {
      return;
    }
    const excludeStatusTrace: LogFilter = (log) => {
      const isStatusTrace = log.level === LogLevel.Trace && log.message.includes('/status');
      return !isStatusTrace;
    };
    const excludePing: LogFilter = (log) => {
      const isLogLevel = log.level === LogLevel.Trace || log.level === LogLevel.Debug;
      const isPing = isLogLevel && log.message.includes(':"ping"}');
      return !isPing;
    };
    const includeOnlyHighLevelErrors: LogFilter = (log) => {
      return log.level === LogLevel.Error && (log.errorLevel === undefined || log.errorLevel === ErrorLevel.High);
    };
    const excludeNetworkErrors: LogFilter = log => {
      const isOperationCouldntBeCompleted = log.message.includes("The operation couldn’t be completed");
      return !isOperationCouldntBeCompleted;
    };

    if (this.platformService.isNativePlatform) {
      this.core.addReporter(
        new ElasticReporterNative(this.elasticLogger, FileSystemPlugin, {
          fileExtension: 'log',
          limits: {
            maxFilesCount: 100,
            maxFileSize: 154857,
            maxBufferCount: 1000,
            maxSaveMessageSize: 30000,
            maxLogMessagesCount: 500,
            autoSaveFile: 1000,
          },
          logsDirectory: `logs-json`,
          sendLogsInterval: PAYMASH_PROFILE.sendingLogsDelay,
          debug: !PAYMASH_PROFILE.isProduction,
          logFilters: [excludeStatusTrace, excludePing],
        })
      );

      this.core.addReporter(new SentryErrorReporter({ logFilters: [includeOnlyHighLevelErrors, excludeNetworkErrors] }));
    } else if (this.platformService.isWeb) {
      this.core.addReporter(new ElasticReporterWeb({ logFilters: [excludeStatusTrace, excludePing] }));
    }

    if (!PAYMASH_PROFILE.isProduction) {
      this.core.addReporter(new ConsoleReporter({ logFilters: [excludeStatusTrace, excludePing] }));
    }
  }

  private registerPlugins() {
    const pluginReceiver = new CapacitorPluginLogReceiver(this.core);
    CapacitorPlugin.logObserver.add(pluginReceiver);
    SocketConnectionPluginLoggerObserver.add(pluginReceiver);
  }

  setField(field: CoreFields, value: string | undefined) {
    this.core.setField(field, value);
  }

  setSentryTag(field: CoreFields, value: string | undefined) {
    try {
      Sentry.setTag(field, value);
    } catch (error) {}
  }

  //region Deprecated Methods
  debug(component: string, message: string) {
    this.core.appendMessageToReporters(LogLevel.Debug, component, message, undefined, undefined, undefined);
  }

  info(component: string, message: string, options?: any) {
    this.core.appendMessageToReporters(LogLevel.Info, component, message, undefined, undefined, options);
  }

  warn(component: string, message: string) {
    this.core.appendMessageToReporters(LogLevel.Warning, component, message, undefined, undefined, undefined);
  }

  error(component: string, message: string, nestedError?: any, errorLevel?: ErrorLevel, options?: any) {
    this.core.appendMessageToReporters(LogLevel.Error, component, message, nestedError, errorLevel, options);
  }

  trace(component: string, message: string, options?: any) {
    this.core.appendMessageToReporters(LogLevel.Trace, component, message, undefined, undefined, options);
  }
  //endregion

  public async getTimApiLogs() {
    const directory = FileSystemPlugin.getSystemDirectory(SystemDirectoryType.Data);
    const logsDir = await directory.getDirectory('timlogs');
    return logsDir.getFiles();
  }

  createLogger(tag: string) {
    return factory.createLogger(tag);
  }
}
