import { Entity } from './entity.class';
/**
 * Created by maksymkunytsia on 8/26/16.
 */

export class Store extends Entity {
  id: number;
  uuid: string;
  name: string;
  email: string;
  homepage: string;
  isWebshopStore: boolean;
  deleted: boolean;
  openingHours: string;
  address: any;
  isShiftOpen: boolean

  constructor(storeData) {
    super(storeData);
    this.id = storeData.id || 0;
    this.name = storeData.name || '';
    this.email = storeData.email || '';
    this.homepage = storeData.homepage || '';
    this.isWebshopStore = storeData.isWebshopStore;
    this.deleted = storeData.deleted;
    this.openingHours = storeData.openingHours || '';
    this.address = storeData.address;
    this.isShiftOpen = storeData.isShiftOpen || false;
  }

}
