import { Injectable } from '@angular/core';
import { Invoice } from '@pos-common/classes/invoice.class';
import { SalesSubChannelTypesEnum, SelfOrderInvoiceStatusTypesEnum, StorageKeys } from '@pos-common/constants';
import { GetAllDataOptions } from '@pos-common/services/db/db-dao.service';
import {
  query,
  group,
  or,
  isNull,
  Query,
  by,
  oneOf,
  greaterOrEqualsThan,
  notEquals,
} from '@paymash/capacitor-database-plugin';
import { SALES_CHANNEL_TYPES } from '@pos-common/constants/sales-channel-types';
import { SelfOrdersApiService } from '@pos-common/services/api/self-orders-api.service';

import { InvoicesProvider } from '@pos-common/services/resources/invoices-db-entity.provider';
import { Observable } from 'rxjs';
import { INVOICE_TYPES } from '@pos-common/constants/invoice-types';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { PosSettingsService } from '@pos-common/services/system/pos-settings.service';
import moment from 'moment';

@Injectable()
export class SelfOrdersService {

  constructor(
    private selfOrderApi: SelfOrdersApiService,
    private invoicesProvider: InvoicesProvider,
    private localStorage: LocalStorage,
    private posSettingsService: PosSettingsService,
  ) { }

  public get localSettingsAutoPrintingOrder(): boolean {
    return !!this.localStorage.get(StorageKeys.settings.autoPrintingKitchenReceiptOnlineInvoice)
  }

  public set localSettingsAutoPrintingOrder(status: boolean) {
    if (status) {
      this.localStorage.set(StorageKeys.settings.autoPrintingKitchenReceiptOnlineInvoice, JSON.stringify(status));
    } else {
      this.localStorage.remove(StorageKeys.settings.autoPrintingKitchenReceiptOnlineInvoice);
    }
  }

  public get listOpenSelfOrderInvoicesFromLocalDB(): Observable<Invoice[]> {
    const duration = this.posSettingsService.getInvoicesKeepDuration();
    const queryParams: Query = query(
      {
        'ext_type': isNull(),
        'cancellationStatus': notEquals('CANCELLED'),
        'salesChannel': oneOf(SALES_CHANNEL_TYPES.WEBSHOP, SALES_CHANNEL_TYPES.ONLINE),
        'subChannel': SalesSubChannelTypesEnum.SELFORDER,
        'selfOrder.fulfillmentState': SelfOrderInvoiceStatusTypesEnum.OPEN,
        'date': greaterOrEqualsThan(moment.utc().subtract(duration, 'day').startOf('day').toISOString()),
        'invoiceType': INVOICE_TYPES.INVOICE
      },
      group({ ['deleted']: isNull() }, or({ ['deleted']: false }))
    );
    const options: GetAllDataOptions = {
      order: [by('fieldToSort', 'descending')],
    };
    return this.invoicesProvider.getListByParams(queryParams , options);
  }

  updatePreparationStatusFromCheckoutPage(invoice: Invoice, status: SelfOrderInvoiceStatusTypesEnum): void {
    const uuid: string = invoice?.selfOrder?.uuid;
    const fulfillmentState: SelfOrderInvoiceStatusTypesEnum = status;

    this.selfOrderApi.updatePreparationStatus({ uuid, fulfillmentState }).then();
  }

  updateSelfOrderService(currentItem: { properties: Invoice }): Invoice {
    return {
      ...currentItem?.properties,
      selfOrder: {
        ...currentItem?.properties.selfOrder,
        uuid: currentItem?.properties.sourceUuid
      }
    } as Invoice;
  }
}
