import { ProductVariantImage } from './image.class';
import { Entity } from './entity.class';
import { VatRate } from './vat-rate.class';

/**
 * Created by maksymkunytsia on 7/12/16.
 */

interface ProductVariantOption {
  name: string;
  value: string;
}

export class ProductVariant extends Entity {
  id: number;
  uuid: string;
  defaultCost: number;
  images: Array<ProductVariantImage>;
  options: Array<ProductVariantOption>;
  price: number;
  product: Entity;
  trackInventory: boolean;
  vatRate: VatRate;
  wasPrice: number;
  weight: number;
  eanCode: string;
  productUuid: string;
  articleNo: string;
  position: number;
  isTakeAwayAllowed: boolean;

  constructor(data: any) {
    super(data);
    this.id = data.id || 0;
    this.defaultCost = data.defaultCost || 0;
    // this.images = data.images || [];
    this.images = this.convertImages(data.images);
    this.options = data.options || [];
    this.price = data?.price || 0;
    this.product = data.product || null;
    this.trackInventory = data.trackInventory || false;
    this.vatRate = data.vatRate || null;
    this.wasPrice = data.wasPrice || 0;
    this.weight = data.weight || 0;
    this.eanCode = data.eanCode || null;
    this.productUuid = data.productUuid || null;
    this.articleNo = data.articleNo || null;
    this.position = data.position;
    this.isTakeAwayAllowed = data.isTakeAwayAllowed || false;
  }

  private convertImages(images: ProductVariantImage[]): ProductVariantImage[] {
    if (images) {
      return images.map((item) => new ProductVariantImage(item));
    }
    return [];
  }

  public getVariantConcatenatedName(): string {
    let optionValues: Array<string> = [];
    for (let i = 0; i < this.options.length; i++) {
      optionValues.push(this.options[i].value);
    }
    if (optionValues.length) return optionValues.join(' ');
    return null;
  }
}
