import { ErrorCondition } from '@pos-common/services/system/adyen/costants/common';

export const AdyenPaymentErrors: Record<string, string> = {
  userCancel: '108 Shopper cancelled tx',
  provideTerminalInfo: 'settings_provide_terminal_info',
  currencyNotSupported: 'is not a supported currency',
  refundNotEnabled: 'Value: Refund, Reason: Not Enabled',
  notEnoughBalance: 'Refusal, Reason: NotEnoughBalanceForAccount',
  NoAcquirerFound: 'NoAcquirerFound',
  declinedOnline: 'Refusal, Reason: 214 Declined online',
  notPresentCard: '126 Shopper did not present card',
  failedToConnect: 'Failed to send message to POI',
  connectFailedToPaymentHost: 'No address associated with hostname',
  connectFailedToServer: 'Failed to connect to',
  timeoutWaitingResponse: 'Timed out waiting for response from POI',
  requestTimeout: 'The request timed out',
  timeout: 'timeout',
  applicationTimeout: '154 Application selection timeout',
  cardRemoved: '107 card removed',
  wrongPin: 'INVALID_PIN',
  cardExpired: 'CARD_EXPIRED',
  statusInProgress: 'InProgress',
};

export const AdyenApiErrors: Record<string, string> = {
  unauthorized: 'Unauthorized',
};

export const AdyenPaymentErrorConditionLegal: string[] = [
  ErrorCondition.InvalidCard,
  ErrorCondition.WrongPIN,
  ErrorCondition.Aborted,
  ErrorCondition.Refusal,
  ErrorCondition.Busy,
  ErrorCondition.Cancel,
  ErrorCondition.InProgress,
  ErrorCondition.NotAllowed
];
