export enum PaymentError {
  REFUND_NOT_SUPPORTED = 'payment_method_does_not_support_refund',
  AMOUNT_ZERO_NOT_SUPPORTED = 'payment_method_does_not_support_zero_amount',
  NO_MYPOS_FOUND = 'no_mypos_device_found',
  NO_MYPOS_GLASS_ACTIVE_TERMINAL = 'no_mypos_device_found',
  NO_PAYMASHPAY_ACTIVE_TERMINAL = 'settings_provide_terminal_info',
  NO_MYPOS_CURRENCY = 'no_mypos_currency_found',
  MYPOS_INVALID_CURRENCY = 'mypos_invalid_currency',
  TRANSACTION_NOT_POSSIBLE_CONNECT_FAIL = 'terminal_transaction_error_not_internet_connect',
  SHIFT_MUST_BE_OPEN = 'SHIFT MUST BE OPEN'
}
