import { ReceiptTextAlign } from '../enum/receipt-text-align.enum';
import { ReceiptFont } from '../enum/printer-receipt-font.enum';
import { IReceiptItem } from '../interfaces/receipt-item.interface';
import { PrinterType } from '../enum/printer-type.enum';
import {
  MyPosReceiptItem,
  MyPosMiniReceiptItem,
  StarReceiptItem,
  SunmiReceiptItem,
  MyPosHubReceiptItem,
  AdyenReceiptItem,
} from './receipt-item';

export enum ReceiptTextSize {
  textSizeOneHeightOne,
  textSizeOneHeightTwo,
  textSizeTwoHeightTwo,
}

export interface IMyPosMiniReceiptItem {
  type: string;
  text?: string;
  align?: string;
  fontSize?: string;
}

export interface IMyPosMiniReceipt {
  data: IMyPosMiniReceiptItem[];
}

export interface IMyPosReceiptItem {
  type: string;
  alignment?: string;
  doubleHeight?: boolean;
  doubleWidth?: boolean;
  text?: string;
  imageEncoded?: string;
}

export interface IReceiptBuilder {
  receipt: IReceiptItem[];
  smallTextRowWidth: number;
  bigTextRowWidth: number;
  lineWidth: number;

  addText(left: string, right?: string, lineWidth?: number): void;

  addTextBetweenSquareBrackets(isPartialCancellation: boolean, left: string, right?: string, lineWidth?: number): void;

  addQrCode(qrCode: string, qrCodeSize?: number): void;

  addImage(image: string): void;

  addFont(font: ReceiptFont): void;

  addTextSize(textSize: ReceiptTextSize): void;

  addTextAlign(textAlign: ReceiptTextAlign): void;

  addUnderLine(symbol?: string): void;

  addBlackLineOnWholeReceiptWidth(): void;

  addCut(): void;

  addPulse(): void;

  addNewLine(): void;

  concatReceiptBuilder(receiptBuilder: IReceiptBuilder): void;
}

interface IReceiptBuilderOptions {
  smallTextRowWidth?: number;
  bigTextRowWidth?: number;
  receiptFont?: ReceiptFont;
}

export class ReceiptBuilder implements IReceiptBuilder {
  private _receipt: IReceiptItem[] = [];
  private _smallTextRowWidth: number;
  private _bigTextRowWidth: number;
  private _lineWidth: number;

  set smallTextRowWidth(value: number) {
    this._smallTextRowWidth = value;
  }

  set bigTextRowWidth(value: number) {
    this._bigTextRowWidth = value;
  }

  get smallTextRowWidth(): number {
    return this._smallTextRowWidth;
  }

  get bigTextRowWidth(): number {
    return this._bigTextRowWidth;
  }

  set lineWidth(value: number) {
    this._lineWidth = value;
  }

  get lineWidth(): number {
    return this._lineWidth;
  }

  constructor(receiptBuilderOptions?: IReceiptBuilderOptions) {
    const smallTextRowWidth = 32;
    const bigTextRowWidth = 16;
    this.smallTextRowWidth = receiptBuilderOptions?.smallTextRowWidth ?? smallTextRowWidth;
    this.bigTextRowWidth = receiptBuilderOptions?.bigTextRowWidth ?? bigTextRowWidth;
    this.lineWidth = this.smallTextRowWidth;
  }

  public addText(left: string, right?: string, lineWidth?: number): void {
    let text = left;
    if (right) {
      const currentLineWidth = lineWidth || this.lineWidth;
      text = this.createFullWidthString(left, right, currentLineWidth);
    }
    const textToAdd: IReceiptItem = { text };
    const receiptLastItem = this.getLastItem();
    if ((receiptLastItem && !receiptLastItem.text) || !receiptLastItem) {
      this._receipt.push(textToAdd);
    } else {
      receiptLastItem.text += text;
    }
  }

  public addTextBetweenSquareBrackets(isPartialCancellation: boolean, left: string, right?: string, lineWidth?: number) {
    if (isPartialCancellation) {
      const nonWhitespace = /\S|$/;
      const leftIndexStart = left.search(nonWhitespace);
      if (leftIndexStart > -1) {
        left = `${left.substr(0, leftIndexStart)}[${left.substr(leftIndexStart, left.length)}`;
      }
      if (right) {
        right += ']';
      } else {
        left += ']';
      }
    }
    this.addText(left, right, lineWidth);
  }

  public addTextWithBreakWordsForRightText(left: string, right: string, firstLineWidth?: number, lineWidth?: number) {
    const currentLineWidth = lineWidth ? lineWidth : this.lineWidth;
    firstLineWidth = firstLineWidth || left.length;
    let rightRestSpace = currentLineWidth - firstLineWidth - 2;
    rightRestSpace = rightRestSpace < 0 ? 0 : rightRestSpace;
    const rightTextFirst = right.slice(0, rightRestSpace);
    right = right.slice(rightTextFirst.length, right.length);
    this.addText(left, rightTextFirst);
    if (right.length > 0) {
      this.addNewLine();
      const split = `(.{${currentLineWidth}})`;
      const regex = new RegExp(split);
      const rightSpitedLine = right.split(regex).filter((i) => i);
      rightSpitedLine.forEach((text, index) => {
        this.addText(text);
        if (index < rightSpitedLine.length - 1) {
          this.addNewLine();
        }
      });
    }
  }

  public addQrCode(qrCode: string, qrCodeSize?: number) {
    qrCodeSize = qrCodeSize || 6;
    this._receipt.push({ qrCode, qrCodeSize });
  }

  public addImage(image: string) {
    this._receipt.push({ image });
  }

  public addFont(font: ReceiptFont) {
    this._receipt.push({ font });
  }

  public addTextSize(textSize: ReceiptTextSize) {
    let textSizeToAdd: IReceiptItem = { textSize: null, textSizeHeight: null };
    switch (textSize) {
      case ReceiptTextSize.textSizeOneHeightOne:
        textSizeToAdd.textSize = 1;
        textSizeToAdd.textSizeHeight = 1;
        this.lineWidth = this.smallTextRowWidth;
        break;
      case ReceiptTextSize.textSizeOneHeightTwo:
        textSizeToAdd.textSize = 1;
        textSizeToAdd.textSizeHeight = 2;
        break;
      case ReceiptTextSize.textSizeTwoHeightTwo:
        textSizeToAdd.textSize = 2;
        textSizeToAdd.textSizeHeight = 2;
        this.lineWidth = this.bigTextRowWidth;
        break;
    }
    this._receipt.push(textSizeToAdd);
  }

  public addTextAlign(textAlign: ReceiptTextAlign) {
    this._receipt.push({ textAlign });
  }

  public addCut() {
    this._receipt.push({ cut: 'Nothing' });
  }

  public addPulse() {
    this._receipt.push({ pulse: 'Nothing' });
  }

  public addNewLine(count?: number) {
    count = count ?? 1;
    for (let i = 0; i < count; i++) {
      this.addText('\n');
    }
  }

  public addUnderLine(symbol?: string) {
    let underLineSymbol: string = '_';
    if (symbol) underLineSymbol = symbol;
    let underlineWidth: number = this.lineWidth;
    this.addText(underLineSymbol.repeat(underlineWidth));
  }

  public addBlackLineOnWholeReceiptWidth(count: number = 2) {
    this.addUnderLine();
    this.addNewLine(count);
  }

  private getLastItem() {
    return this._receipt[this._receipt.length - 1];
  }

  public concatReceiptBuilder(receiptBuilder: ReceiptBuilder): void {
    this._receipt = this._receipt.concat(...receiptBuilder.receipt);
  }

  /***
   * @deprecated
   * Please use receiptForEpson or receiptAsPlainText instead of receipt
   * */
  get receipt(): any[] {
    return this._receipt;
  }

  /***
   * @deprecated
   * Please use getReceiptByPrinterType instead of receiptForEpson
   * */
  get receiptForEpson(): any[] {
    return this._receipt;
  }

  get receiptAsPlainText(): string {
    return this._receipt.reduce((text, item) => {
      text += item.text ?? '';
      return text;
    }, '');
  }

  get isOpenCashRegister(): boolean {
    return this._receipt.filter((item) => item.pulse).length > 0;
  }

  getReceiptByPrinterType(type: PrinterType) {
    const receipt = [...this._receipt];
    const receiptItem = this.getReceiptItem(type);
    return receiptItem?.getReceipt(receipt) ?? receipt;
  }

  private getReceiptItem(type: PrinterType) {
    switch (type) {
      case PrinterType.MyPos:
        return new MyPosReceiptItem();
      case PrinterType.MyPosMini:
        return new MyPosMiniReceiptItem();
      case PrinterType.Star:
        return new StarReceiptItem();
      case PrinterType.Sunmi:
        return new SunmiReceiptItem();
      case PrinterType.Adyen:
        return new AdyenReceiptItem();
    }
  }

  get receiptForMyPosHub() {
    const myPosReceiptItem = new MyPosHubReceiptItem();
    return myPosReceiptItem.getReceipt(this._receipt);
  }

  private createFullWidthString(left: string, right: string, lineWidth: number) {
    if (left === null || right === null) return null;
    const emptyLineWidth = lineWidth - left.length - right.length;
    const newLine = left + ' '.repeat(emptyLineWidth < 0 ? 0 : emptyLineWidth) + right;
    if (newLine.length > lineWidth) {
      return this.getWrappedLines(left, right, lineWidth);
    }
    return newLine;
  }

  private getWrappedLines(left: string, right: string, lineWidth: number) {
    if (left.length > right.length) {
      const { firstLine, otherLine } = this.getFirstAndOtherWrappedLines(left, right, lineWidth);
      const trimOtherLine = otherLine ? otherLine.trim() : otherLine;
      return (
        this.createFullWidthString(firstLine, `  ${right}`, lineWidth) + '\n' + this.createFullWidthString(trimOtherLine, '', lineWidth)
      );
    }

    const { firstLine, otherLine } = this.getFirstAndOtherWrappedLines(right, left, lineWidth);
    const trimFirstLine: string = firstLine ? firstLine.trim() : firstLine;
    const trimOtherLine: string = otherLine ? otherLine.trim() : otherLine;
    return (
      this.createFullWidthString(left, '  ' + trimFirstLine, lineWidth) +
      '\n' +
      this.createFullWidthString('', trimOtherLine, lineWidth)
    );
  }

  private getFirstAndOtherWrappedLines(value: string, startValue: string, lineWidth: number) {
    let firstLine = '';
    let otherLine = '';
    let stringCompleted = false;
    const defaultLength = 2 + startValue.length;
    const spitedLine: string[] = this.splitReceiptString(value.split(/(\s+)/), lineWidth);
    for (let i = 0; i < spitedLine.length; i++) {
      if (defaultLength + firstLine.length + spitedLine[i].length < lineWidth && !stringCompleted) {
        firstLine += spitedLine[i];
      } else {
        otherLine += spitedLine[i];
        stringCompleted = true;
      }
    }
    return {
      firstLine,
      otherLine,
    };
  }

  private splitReceiptString(stringsArray: any[], lineWidth: number, splitPosition: number = 4): Array<any> {
    for (let i = 0; i < stringsArray.length; i++) {
      if (stringsArray[i].length >= lineWidth) {
        let firstArrEndIndex: number = i === 0 ? 0 : i;
        let result = stringsArray.slice(0, firstArrEndIndex);
        result.push(stringsArray[i].slice(0, lineWidth - splitPosition));
        result.push(' ');
        result.push(stringsArray[i].slice(lineWidth - splitPosition, stringsArray[i].length));
        let lastStringsArray: Array<any> = stringsArray.slice(firstArrEndIndex + 1, stringsArray.length);
        return this.splitReceiptString(result.concat(lastStringsArray), lineWidth, splitPosition);
      }
    }
    return stringsArray;
  }
}
