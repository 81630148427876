/**
 * Created by maksymkunytsia on 8/23/16.
 */
import { Image } from './image.class';
import { Entity } from './entity.class';
import { Store } from './store.class';

export class Employee extends Entity {
  public uuid: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public image: Image;
  public shortPassword: string;
  public shortPasswordType: string;
  public hasReportPermission: boolean;
  public hasCancellationPermission: boolean;
  public hasSettingsPermission: boolean;
  public hasAdminPermission: boolean;
  public deleted: boolean;
  public employeeDisplayName: string;
  public stores: Store[];
  public colorInCalendar: string;

  constructor(employeeData: any) {
    super(employeeData);
    this.firstName = employeeData?.firstName || '';
    this.lastName = employeeData?.lastName || '';
    this.email = employeeData?.email || '';
    this.image = employeeData?.image ? new Image(employeeData.image) : null;
    this.shortPassword = employeeData?.shortPassword || '';
    this.shortPasswordType = employeeData?.shortPasswordType || 'NONE';
    this.hasReportPermission = employeeData?.hasReportPermission || false;
    this.hasCancellationPermission = employeeData?.hasCancellationPermission || false;
    this.hasSettingsPermission = employeeData?.hasSettingsPermission || false;
    this.hasAdminPermission = employeeData?.hasAdminPermission || false;
    this.deleted = employeeData.deleted || false;
    this.employeeDisplayName = this.getDisplayName();
    this.stores = this.getStores(employeeData?.stores);
    this.colorInCalendar = employeeData?.colorInCalendar || '';
  }

  private getDisplayName(): string {
    return [this.firstName, this.lastName].filter((name) => name).join(' ');
  }

  private getStores(stores: any[]) {
    stores = stores ?? [];
    return stores.map((store) => new Store(store));
  }
}
