import * as moment from 'moment';
import { PRODUCT_TYPES } from '../constants/product-types';
import { MathUtils } from '../services/utils/math.utils';
import { Image } from './image.class';
import { Entity } from './entity.class';
import { GiftCard } from '../../modules/assing-gift-card/gift-card';
import { GenerateRandomColor } from './generate-random-color.class';

export enum SpecialTaxing {
  takeAway = 'TAKE_AWAY',
  inHouse = 'IN_HOUSE',
}

export class InvoiceEntry extends Entity {
  uuid: string;
  name: string;
  shortName: string;
  type: string;
  quantity: number;
  quantityForKitchenReceipt: number;
  price: number;
  taxRate: number;
  totalTaxAmount: number;
  totalAmount: number;
  position: number;
  note: string;
  bgColor: string;
  deleted: boolean;
  image: Image;
  modificationDate: string;
  discountedPrice: number;
  discount: number;
  discountPercentage: number;
  totalDiscountAmount: number;
  employee: Object;
  customerIdentifier: string;
  productVariant: { uuid: string };
  product: { uuid: string };
  productCategory: Object;
  store: Object;
  inventoryStore: Object;
  quantityForSubtract: number;
  specialTaxing: SpecialTaxing;
  virtualPrintersUuid: string;
  giftCard: GiftCard;
  giftCardCode: string;
  giftCardPhysicalCode: string;
  isTipping: boolean;
  isExternalTipping: boolean
  guestNumber: number;
  wasPrice: number;
  isSale: boolean;

  constructor(data) {
    super(data);
    this.type = PRODUCT_TYPES[data.type] || PRODUCT_TYPES.PRODUCT;
    this.name = data?.name || '';
    this.shortName = data.shortName || null;
    this.quantity = data?.quantity === undefined ? 1 : data.quantity;
    this.quantityForKitchenReceipt = data.quantityForKitchenReceipt || 0;
    this.price = data?.price || 0;
    this.taxRate = data.taxRate !== undefined && data.taxRate !== null ? data.taxRate : 0;
    this.totalTaxAmount = data.totalTaxAmount || 0;
    this.totalAmount = data.totalAmount || 0;
    this.position = data.position || 0;
    this.note = data.note || null;
    this.bgColor = data.bgColor || new GenerateRandomColor().generateColorByText(this.name);
    this.deleted = data.deleted || false;
    this.image = data.image ? new Image(data.image) : null;
    this.modificationDate = moment.utc(data.modificationDate).toISOString() || moment.utc().toISOString();
    this.wasPrice = data.wasPrice || 0;
    this.isSale = this.wasPrice && this.wasPrice > this.price;

    this.discountedPrice = data.discountedPrice || 0;
    this.discount = data.discount || 0;
    this.discountPercentage = data.discountPercentage || 0;
    this.totalDiscountAmount = data.totalDiscountAmount || 0;

    this.employee = data.employee || null;
    this.customerIdentifier = data.customerIdentifier || null;
    this.productVariant = data.productVariant || null;
    this.product = data.product || null;
    this.productCategory = data.productCategory || null;
    this.store = data.store || null;
    this.inventoryStore = data.inventoryStore || null;
    this.quantityForSubtract = data.quantityForSubtract || 0;

    this.specialTaxing = data.specialTaxing || SpecialTaxing.inHouse;
    this.virtualPrintersUuid = data.virtualPrintersUuid || null;
    this.giftCard = data.giftCard ? new GiftCard(data.giftCard) : null;
    this.giftCardCode = data.giftCardCode || null;
    this.giftCardPhysicalCode = data.giftCardPhysicalCode || null;
    this.isTipping = data.isTipping || data.type === 'TIPS' || false;
    this.isExternalTipping = data.isExternalTipping || false;
    this.guestNumber = data.guestNumber || 1;
  }

  update(data) {
    this.name = data?.name || this.name;
    this.shortName = data.shortName || this.shortName;
    this.note = data.note === undefined ? this.note : data.note;
    this.price = data?.price === null ? this.price : data.price;
    this.discountedPrice = data.discountedPrice === null ? this.discountedPrice : data.discountedPrice;
    this.discount = data.discount === null ? this.discount : data.discount;
    this.discountPercentage = data.discountPercentage === null ? this.discountPercentage : data.discountPercentage;
    this.totalDiscountAmount = data.totalDiscountAmount || this.totalDiscountAmount;
    this.taxRate = data.taxRate || this.taxRate;
    this.image = data.image || this.image;
    this.quantity = data?.quantity || this.quantity;
    this.quantityForKitchenReceipt = data.quantityForKitchenReceipt || this.quantityForKitchenReceipt;
    this.specialTaxing = data.specialTaxing || this.specialTaxing;
    this.virtualPrintersUuid = data.virtualPrintersUuid || null;
    this.giftCardCode = data.giftCardCode || null;
    this.giftCardPhysicalCode = data.giftCardPhysicalCode || null;
    this.productVariant = this.getProductVariant(data);
    this.product = data.product || null;
    this.guestNumber = data.guestNumber || 1;
    this.customerIdentifier = data.customerIdentifier || null;
    this.wasPrice = data.wasPrice || this.wasPrice;
    this.isSale = this.wasPrice && this.wasPrice > this.price;
  }

  get isGuest() {
    return this.type === PRODUCT_TYPES.CATEGORY;
  }

  private static hasEntryDiscount(entry: InvoiceEntry): boolean {
    return entry.discount && entry.discount !== 0;
  }

  private static hasEntryDiscountPercentage(entry: InvoiceEntry): boolean {
    return entry.discountPercentage && entry.discountPercentage !== 0;
  }

  static calculateEntryDiscountedPrice(entry: InvoiceEntry) {
    if (!entry) return entry;
    let discountAmount: number;
    if (InvoiceEntry.hasEntryDiscount(entry)) {
      entry.discountedPrice = entry.price - entry.discount;
    } else if (InvoiceEntry.hasEntryDiscountPercentage(entry)) {
      discountAmount = InvoiceEntry.calculateDiscountAmount(entry?.price, entry.discountPercentage);
      entry.discountedPrice = entry.price - discountAmount;
    } else {
      entry.discountedPrice = entry?.price;
    }

    entry.discountedPrice = entry.discountedPrice < 0 ? 0 : entry.discountedPrice;
    return entry;
  }

  calculateDiscountedPrice(): InvoiceEntry {
    return InvoiceEntry.calculateEntryDiscountedPrice(this);
  }

  static calculateDiscountAmount(price: number, discountPercentage: number): number {
    return MathUtils.roundHalfUp(price * (discountPercentage / 100), 2);
  }

  private getProductVariant(data: any) {
    if (data.variant) {
      return { uuid: data.variant?.uuid };
    } else if (data.productVariant) {
      return { uuid: data.productVariant?.uuid };
    }
    return null;
  }

  markAsDeleted() {
    this.deleted = true;
    this.quantityForKitchenReceipt = 0;
    this.quantity = 0;
  }
}
