import { Injectable } from '@angular/core';
import { LocalStorage } from '../utils/localstorage.utils';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { SearchUtils } from '@pos-common/services';
import { GenerateRandomColor } from '@pos-common/classes';
import { FileSystemPlugin, SystemDirectoryType } from '@spryrocks/capacitor-filesystem-plugin';

export interface DbResponse<T = any> {
  status: number;
  data: T;
  collection?: string;
  message?: string;
  dbMessage: boolean;
  error?: any;
}

export const success = <T>(data: any, options?: { status?: number; collection?: string; message?: string }): DbResponse<T> => ({
  status: options?.status ?? 200,
  collection: options?.collection,
  data,
  message: options?.message,
  dbMessage: true,
});

export const failure = <T>(
  data: any,
  options?: { status?: number; collection?: string; error?: any; message?: string }
): DbResponse<T> => ({
  status: options?.status ?? 400,
  collection: options?.collection,
  data,
  message: options?.message,
  dbMessage: true,
  error: options?.error,
});

@Injectable()
export class DbDaoUtils {
  constructor(
    private readonly localStorage: LocalStorage,
    private readonly nativeStorage: NativeStorage,
    private readonly searchUtils: SearchUtils,
  ) {}

  public setDbVersion(dbVersion: string, companyPrefix: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.nativeStorage.setItem('dbVersion-' + companyPrefix, { value: dbVersion }).then(resolve, (err) => {
        if (err === 'cordova_not_available') {
          this.localStorage.set('dbVersion-' + companyPrefix, dbVersion);
          resolve();
        } else {
          reject(err);
        }
      });
    });
  }

  public getDbVersion(companyPrefix: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.nativeStorage.getItem('dbVersion-' + companyPrefix).then(
        (data) => resolve(data && data.value ? data.value : null),
        (err) => {
          if (err === 'cordova_not_available') {
            resolve(this.localStorage.get('dbVersion-' + companyPrefix));
          } else {
            reject(err);
          }
        }
      );
    });
  }

  public prepareItem = (item: any, collection: string) => {
    // if (collection === UPDATES_TYPES.Invoice.type) {
    //   item = !item.gastronomyTable ? new Invoice(item) : item;
    // }

    this.addFieldsToSort(collection, item);
    this.addFieldsToSearch(collection, item);

    if (collection === UPDATES_TYPES.Product.type) {
      item.stores = item.stores || [];
      item.stores = item.stores.map((store) => store?.uuid || store);
    }

    if (collection === UPDATES_TYPES.Product.type || collection === UPDATES_TYPES.ProductCategory.type) {
      if (!item.virtualPrinter) item.virtualPrinter = null;
    }

    this.addCorrectIndexFieldsToItem(collection, item);

    // delete item._syncState;

    // item = { _id: item.uuid, ...item };

    return item;
  };

  public addFieldsToSort(collectionName: string, itemToTransform: any) {
    if (this.skipAddField(itemToTransform)) {
      return;
    }
    let fieldToSort = '';
    switch (collectionName) {
      case UPDATES_TYPES.Customer.type:
        fieldToSort = itemToTransform.firstName + itemToTransform.lastName || itemToTransform.email || 'ZZ';
        itemToTransform.fieldToSort = fieldToSort.toLowerCase();
        break;
      case UPDATES_TYPES.Invoice.type:
        const date = itemToTransform.date || itemToTransform.modificationDate;
        fieldToSort = new Date(date).valueOf().toString();
        itemToTransform.fieldToSort = fieldToSort;
        break;
      case UPDATES_TYPES.Product.type:
        fieldToSort = itemToTransform.name.toLowerCase();
        itemToTransform.fieldToSort = fieldToSort;
        break;
    }
  }

  public addFieldsToSearch(collectionName: string, itemToTransform: any) {
    if (this.skipAddField(itemToTransform)) {
      return;
    }
    let arrayToSearch: string[] = [];
    switch (collectionName) {
      case UPDATES_TYPES.Customer.type:
        arrayToSearch = [
          itemToTransform?.firstName || '',
          itemToTransform?.lastName || '',
          itemToTransform?.email || '',
          itemToTransform?.note || '',
          itemToTransform?.address?.business || '',
          itemToTransform?.address?.postalCode || '',
          itemToTransform?.address?.address || '',
          itemToTransform?.address?.phoneNr || '',
          itemToTransform?.address?.addition || '',
          itemToTransform?.vatNumber || '',
        ];
        itemToTransform.fieldToSearch = this.getFilteredValuesForField(arrayToSearch);
        break;
      case UPDATES_TYPES.Product.type:
        arrayToSearch = [itemToTransform?.name || '', itemToTransform?.additionalInformation || ''];
        if (itemToTransform?.variants?.length) {
          const variants = itemToTransform.variants.map((variant) => [variant?.eanCode || '', variant?.articleNo || '']);
          arrayToSearch = [...arrayToSearch, ...[].concat(...variants)];
        }
        itemToTransform.fieldToSearch = this.getFilteredValuesForField(arrayToSearch);
        break;
      case UPDATES_TYPES.Invoice.type:
        arrayToSearch = [itemToTransform?.invoiceId || '', itemToTransform?.sequentId || ''];
        if (itemToTransform.ext_type === 'customer') {
          arrayToSearch = [itemToTransform?.firstName || '', itemToTransform?.lastName || '', itemToTransform?.address?.business || ''];
        }
        itemToTransform.fieldToSearch = this.getFilteredValuesForField(arrayToSearch);
        break;
    }
  }

  public addExtTypeFieldsToSearch(collectionName: string, itemToTransform: any) {
    let arrayToSearch: string[] = [];
    switch (collectionName) {
      case UPDATES_TYPES.Invoice.type:
        if (itemToTransform.ext_type === 'customer') {
          arrayToSearch = [itemToTransform?.firstName || '', itemToTransform?.lastName || '', itemToTransform?.address?.business || ''];
        }
        itemToTransform.fieldToSearch = this.getFilteredValuesForField(arrayToSearch);
        break;
    }
  }

  public prepareItems = (items: any[], collection: string): any[] =>
    this.removeDuplicates(items).map((item) => this.prepareItem(item, collection));

  private removeDuplicates(array: Array<{ uuid: string }>): Array<{ uuid: string }> {
    if (!array) return array;
    return array.filter((item, index, self) => index === self.findIndex((t) => t.uuid === item.uuid));
  }

  private addCorrectIndexFieldsToItem(collectionName: string, itemToTransform: any) {
    switch (collectionName) {
      case UPDATES_TYPES.Invoice.type:
        if (itemToTransform.store) itemToTransform.storeUuid = itemToTransform.store.uuid;
        itemToTransform.customer ? (itemToTransform.customerUuid = itemToTransform.customer.uuid) : (itemToTransform.customerUuid = null);
        break;
      case UPDATES_TYPES.ProductCategory.type:
        itemToTransform.bgColor = new GenerateRandomColor().generateColorByText(itemToTransform.name);
        itemToTransform.parentProductCategory
          ? (itemToTransform.parentCategoryUuid = itemToTransform.parentProductCategory.uuid)
          : (itemToTransform.parentCategoryUuid = null);
        break;
      case UPDATES_TYPES.ProductVariant.type:
        itemToTransform.product ? (itemToTransform.productUuid = itemToTransform.product.uuid) : (itemToTransform.productUuid = null);
        break;
      case UPDATES_TYPES.Product.type:
        itemToTransform.bgColor = new GenerateRandomColor().generateColorByText(itemToTransform.name);
        if (itemToTransform.productInCategories.length === 0) {
          itemToTransform.productCategoriesUuid = 'root';
        } else {
          itemToTransform.productCategoriesUuid = '';
          for (let i = 0; i < itemToTransform.productInCategories.length; i++) {
            let current = itemToTransform.productInCategories[i];
            let uuidToAdd = current.category ? current.category.uuid : 'root';
            itemToTransform.productCategoriesUuid += uuidToAdd + ',';
          }
        }

        itemToTransform.productVariantsUuid = '';
        for (let i = 0; i < itemToTransform.variants.length; i++) {
          let current = itemToTransform.variants[i];
          itemToTransform.productVariantsUuid += current.uuid + ',';
        }
        itemToTransform.isSale = itemToTransform.wasPrice > itemToTransform?.price;
        break;
      case UPDATES_TYPES.Inventory.type:
        itemToTransform.productVariant
          ? (itemToTransform.productVariantUuid = itemToTransform.productVariant.uuid)
          : (itemToTransform.productVariantUuid = null);
        break;
    }
  }

  private getFilteredValuesForField(values: string[]) {
    const result = values.filter((value) => !!value).join(' ');
    return this.searchUtils.replaceForSearch(result);
  }

  private skipAddField(itemToTransform: any): boolean {
    return !itemToTransform?.hasOwnProperty('deleted') || !!itemToTransform?.deleted;
  }

  public async hasLokiDbFiles(companyUuid: string): Promise<boolean> {
    const root = FileSystemPlugin.getSystemDirectory(SystemDirectoryType.Data);
    const files = await root.getFiles();
    const directories = await root.getDirectories();
    const companyDb = this.filterDatabaseFilesByCompanyFS([...files, ...directories], companyUuid, UPDATES_TYPES.Company.type);
    return !!companyDb.length;
  }

  private filterDatabaseFilesByCompanyFS(files: any[], companyUuid: string, collection: string): any[] {
    const regexPattern = `pos__PPD-${companyUuid}-${collection}\\d*\\.json`;
    const regex = new RegExp(regexPattern);
    return files.filter((file) => regex.test(file.name));
  }
}
