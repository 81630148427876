import { Injectable } from '@angular/core';
import { Entry, FileSystemPlugin, SystemDirectoryType, Directory, File } from '@spryrocks/capacitor-filesystem-plugin';
import {
  Database,
  FindOptions,
  From,
  IFindIterator,
  Join,
  JsonValidationDatabasePluginError,
  oneOf,
  Order,
  Query,
} from '@paymash/capacitor-database-plugin';
import { DbDaoUtils, DbResponse, failure, success } from '@pos-common/services/db/db-dao.utils';
import { Migration } from './migrations';
import { Observable } from 'rxjs';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { LogService } from '@pos-common/services/system/logger';
import { ErrorLevel } from '@spryrocks/logger';
import { NgZoneService } from '../system';

type GetAllDataOptionsBase = {
  join?: Join[];
  limit?: number;
  offset?: number;
  order?: Order;
  select?: string[];
};

export type GetAllDataOptions = GetAllDataOptionsBase;

export type GetAllDataChunkedOptions = GetAllDataOptionsBase & {
  chunkSize?: number;
};

export type GetAllDataIteratorOptions = GetAllDataOptionsBase & {
  pageSize?: number;
};

export type Record = object;

export interface IDbDaoService {
  initDatabase(companyPrefix: string): Promise<boolean>;

  getAllData<T = any>(collection: string, query?: Query, options?: GetAllDataOptions): Promise<DbResponse<T>>;

  getAllDataRaw(collection: string, query?: Query, options?: GetAllDataOptions): Promise<Record[]>;

  getAllDataChunked<T>(collection: string, query?: Query, options?: GetAllDataChunkedOptions): Observable<Array<T>>;

  getAllDataIterator<T>(from: From | string, query?: Query, options?: GetAllDataIteratorOptions): Promise<IFindIterator<T>>;

  count(collection: string, query?: Query): Promise<number>;

  upsertDataToCollection(collection: string, data: Array<any>, options?: { patch?: boolean }): Promise<DbResponse>;

  getDataByUUID(collection: string, uuid: string): Promise<DbResponse>;

  getDataByUUIDRaw(collection: string, uuid: string): Promise<Record>;

  deleteDatabase(collection: string): Promise<void>;

  removeDataFromCollection(collection: string, dataToRemove: any): Promise<DbResponse>;

  updateItemFieldsInCollection(collection: string, data: { uuid: string }): Promise<DbResponse>;

  enableLogs(enable: boolean): void;
}

@Injectable()
export class DbDaoService implements IDbDaoService {
  private readonly logger = this.logService.createLogger('DbDaoService');
  private companyPrefix: string | undefined;

  constructor(
    private readonly dbDaoUtils: DbDaoUtils,
    private readonly migration: Migration,
    private readonly ngZoneService: NgZoneService,
    private logService: LogService
  ) {}

  public async initDatabase(companyPrefix: string): Promise<boolean> {
    this.companyPrefix = companyPrefix;

    await Database.setup({
      idKey: 'uuid',
    });

    await this.migration.runMigrations(companyPrefix);

    return true;
  }

  public getAllData<T = any>(from: From | string, query?: Query, options?: GetAllDataOptions): Promise<DbResponse<T>> {
    return this.getAllDataRaw<T>(from, query, options).then((data) => success<T>(data));
  }

  public getAllDataRaw<T>(from: From | string, query?: Query, options?: GetAllDataOptions): Promise<T[]> {
    return this.ngZoneService.runOutsideAngular(() => Database.find<T>(this.prepareFrom(from), this.createFindOptions(query, options)));
  }

  public getAllDataChunked<T = any>(from: From | string, query?: Query, options?: GetAllDataChunkedOptions): Observable<Array<T>> {
    return this.ngZoneService.runOutsideAngular(() =>
      Database.findChunked<T>(this.prepareFrom(from), {
        ...this.createFindOptions(query, options),
        chunkSize: options?.chunkSize,
      })
    );
  }

  public getAllDataIterator<T>(from: From | string, query?: Query, options?: GetAllDataIteratorOptions): Promise<IFindIterator<T>> {
    return this.ngZoneService.runOutsideAngular(() =>
      Database.findIterator<T>(this.prepareFrom(from), {
        ...this.createFindOptions(query, options),
        pageSize: options?.pageSize,
      })
    );
  }

  public count(collection: string, query?: Query): Promise<number> {
    return this.ngZoneService.runOutsideAngular(() => Database.count(this.getCollection(collection), { where: query }));
  }

  private async processUpdatedData(collectionName: string, data: Array<any>) {
    if (collectionName === UPDATES_TYPES.Product.type) {
      await this.processProductsUpdate(data);
    } else if (collectionName === UPDATES_TYPES.Employee.type) {
      await this.processEmployeesUpdate(data);
    } else if (collectionName === UPDATES_TYPES.Customer.type) {
      await this.processCustomersUpdate(data);
    } else if (collectionName === UPDATES_TYPES.Store.type) {
      await this.processStoresUpdate(data);
    }
  }

  private async processProductsUpdate(data: Array<any>) {
    const productUuids: string[] = [];

    const categoryStoreExtra: {
      productUuid: string;
      categoryUuid: string;
      storeUuid: string;
      ext_type: 'category_store';
    }[] = [];

    for (const product of data) {
      const productUuid = product?.uuid;
      productUuids.push(productUuid);

      const categories = product.productCategoriesUuid.split(',').filter((c) => c !== '');
      const stores = product.stores;

      for (const categoryUuid of categories) {
        for (const storeUuid of stores) {
          categoryStoreExtra.push({
            productUuid,
            categoryUuid,
            storeUuid,
            ext_type: 'category_store',
          });
        }
      }
    }

    const collection = this.getCollection(UPDATES_TYPES.Product.type);
    await Database.delete(collection, {
      where: {
        ext_type: 'category_store',
        productUuid: oneOf(...productUuids),
      },
    });
    await Database.addMany(collection, categoryStoreExtra);
  }

  private async processEmployeesUpdate(employees: Array<any>) {
    const invoicesExt = employees.map((e) => ({
      uuid: e?.uuid,
      ext_type: 'employee',
      firstName: e?.firstName,
      lastName: e?.lastName,
    }));
    await Database.addOrUpdateMany(this.getCollection(UPDATES_TYPES.Invoice.type), invoicesExt);
  }

  private async processCustomersUpdate(customers: Array<any>) {
    const collectionName = UPDATES_TYPES.Invoice.type;
    const customersExt = customers.map((c) => {
      const newCustomer = {
        uuid: c?.uuid,
        ext_type: 'customer',
        firstName: c?.firstName,
        lastName: c?.lastName,
        address: c?.address,
        email: c?.email,
      };
      this.dbDaoUtils.addExtTypeFieldsToSearch(collectionName, newCustomer);
      return newCustomer;
    });
    await Database.addOrUpdateMany(this.getCollection(collectionName), customersExt);
  }

  private async processStoresUpdate(stores: Array<any>) {
    const storesExt = stores.map((s) => ({
      uuid: s?.uuid,
      ext_type: 'store',
      name: s?.name,
    }));
    await Database.addOrUpdateMany(this.getCollection(UPDATES_TYPES.Invoice.type), storesExt);
  }
  public async upsertDataToCollection(collection: string, data: Array<any>, options?: { patch?: boolean }): Promise<DbResponse> {
    data = this.dbDaoUtils.prepareItems(data, collection);

    try {
      await Database.addOrUpdateMany(this.getCollection(collection), data, { patch: options?.patch });
    } catch (e) {
      if (e instanceof JsonValidationDatabasePluginError) {
        this.logger.error(e, 'Database json validation error', { level: ErrorLevel.High }, { data });
      }
      throw e;
    }

    await this.processUpdatedData(collection, data);

    return success(data, { status: 201, collection, message: 'Data successfully INSERTed!' });
  }

  public async getDataByUUID(collection: string, uuid: string): Promise<DbResponse> {
    const data = await this.getDataByUUIDRaw(collection, uuid);
    if (data) {
      return success(data, { message: 'Data successfully FOUND!', collection });
    } else {
      throw failure(uuid, { status: 404, collection, message: 'Data NOT FOUND' });
    }
  }

  getDataByUUIDRaw(collection: string, uuid: string): Promise<Record> {
    return Database.findById(this.getCollection(collection), uuid);
  }

  public async deleteDatabase(collection: string): Promise<void> {
    await Database.deleteCollection(this.getCollection(collection));
  }

  public async remove(collection: string, query?: Query): Promise<DbResponse> {
    await Database.delete(this.getCollection(collection), { where: query });

    return success([], { collection, message: 'Data already REMOVed!' });
  }

  public async removeDataFromCollection(collection: string, dataToRemove: any): Promise<DbResponse> {
    dataToRemove = this.dbDaoUtils.prepareItem(dataToRemove, collection);
    if (collection === 'Customer' || collection === 'Employee') {
      dataToRemove['deleted'] = true;
      await this.upsertDataToCollection(collection, [dataToRemove]);
    } else {
      await this.remove(collection, { uuid: dataToRemove?.uuid });
    }
    return success(dataToRemove, { collection, message: 'Data successfully REMOVed!' });
  }

  public async updateItemFieldsInCollection(collection: string, data: { uuid: string }): Promise<DbResponse> {
    data = this.dbDaoUtils.prepareItem(data, collection);
    await Database.update(this.getCollection(collection), data, { patch: true });
    return success(data, { collection, message: 'Fields successfully UPDATED!' });
  }

  public async getFilesListForBackup(companyUuid: string): Promise<Entry[]> {
    const collections = Object.keys(UPDATES_TYPES).filter((key) => !!UPDATES_TYPES[key].URL);
    const rootDirectory = FileSystemPlugin.getSystemDirectory(SystemDirectoryType.Data);
    const backupsDirectory = await rootDirectory.getDirectory('DatabaseBackup', { create: true });
    const backupName = new Date().getTime().toString();
    const backupDirectory = await backupsDirectory.getDirectory(backupName, { create: true });

    const files: Entry[] = [];

    for (let collection of collections) {
      const rawCollection = this.getCollection(collection, { companyPrefix: companyUuid });
      const fileName = `${rawCollection}.json`;
      const file = await this.exportCollection(rawCollection, backupDirectory, fileName);
      files.push(file);
    }

    return files;
  }

  private async exportCollection(rawCollection: string, directory: Directory, fileName: string): Promise<File> {
    const iterator = await Database.findIterator(rawCollection, { offset: 50 });
    let data: any[] = undefined;

    let isFirst = true;

    const file = await directory.getFile(fileName);

    await file.useFileWriter(
      async (writer) => {
        await writer.writeString('[\n');

        while ((data = await iterator.next())) {
          let json = '';
          for (let item of data) {
            json += JSON.stringify(item);
            if (!isFirst) {
              json += ',\n';
            }
            isFirst = false;
          }
          await writer.writeString(json);
        }

        await writer.writeString('\n]\n');
      },
      { append: true }
    );
    return file;
  }

  private prepareFrom(from: From | string): From {
    if (typeof from === 'string') return { name: this.getCollection(from), alias: undefined };
    else return { name: this.getCollection(from?.name), alias: from.alias };
  }

  public getCollection(collection: string, options?: { companyPrefix?: string }): string {
    let companyPrefix = options?.companyPrefix ?? this.companyPrefix;
    if (!companyPrefix) throw new Error('please init database firstly');
    return `${companyPrefix}_${collection}`;
  }

  private prepareJoin(join: Join[] | undefined): Join[] | undefined {
    if (!join) return null;
    return join.map((j) => ({ type: j.type, from: this.prepareFrom(j.from), on: j.on }));
  }

  private createFindOptions(query: Query | undefined, options: GetAllDataOptionsBase | undefined): FindOptions {
    return {
      join: this.prepareJoin(options?.join),
      where: query,
      order: options?.order,
      limit: options?.limit,
      offset: options?.offset,
      select: options?.select,
    };
  }

  async enableLogs(enable: boolean) {
    if (enable) {
      await Database.setLogLevels(undefined);
    } else {
      await Database.setLogLevels([]);
    }
  }
}
