export const getGratuityAmountFromMerchantReceipt = (receipt: string): { currency: string, amount: number } => {
  if (typeof receipt !== "string") {
    return null;
  }

  const regex: RegExp = /Gratuity\s+([A-Z]{3})\s+([\d.]+)/;
  const match: RegExpMatchArray = receipt.match(regex);
  if (match) {
    return {
      currency: match[1], // Currency code (e.g., CHF)
      amount: parseFloat(match[2]) // Gratuity amount as a number
    };
  }
  return null;
}
