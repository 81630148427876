import { Injectable } from '@angular/core';
import { InvoiceEntry } from '@pos-common/classes/invoice-entry.class';
import { Product } from '@pos-common/classes/product.class';
import { UPDATES_TYPES } from '@pos-common/constants/updates-types.const';
import { DbDaoService } from '@pos-common/services/db/db-dao.service';
import { PosSettingsService } from '../pos-settings.service';
import { Invoice } from '@pos-common/classes/invoice.class';
import { InvoicesService } from '../invoices.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { PRODUCT_TYPES } from '@pos-common/constants/product-types';
import { LogService } from '../logger/log.service';

export type TippingEventSource = 'pos' | 'paymashPay'

export interface TippingEventPayload {
  amount: number;
  source: TippingEventSource
}

@Injectable()
export class TippingService {
  private _isTipping = false;
  private product: Product = null;
  private invoicesTipInvoiceEntries = new Map<string, string>();
  private _tip = new BehaviorSubject<TippingEventPayload>({ amount: 0, source: 'pos' });
  private _productUuid: string;
  private readonly logger = this.logService.createLogger('TippingService');

  constructor(
    private posSettingsService: PosSettingsService,
    private dbDaoService: DbDaoService,
    private invoicesService: InvoicesService,
    private logService: LogService
  ) {
    this.ngOnInit();
  }

  private ngOnInit() {
    this.getTippingStatusEvent().subscribe((isTipping) => {
      this._isTipping = isTipping;
      this.setTippingProduct();
    });
    this.getDefaultTippingProductEvent().subscribe(() => {
      this.product = null;
      this.setTippingProduct();
    });
    this.init();
  }

  init() {
    this._isTipping = this.posSettingsService.getTippingStatus();
    this.setTippingProduct();
  }

  get isTipping(): boolean {
    return this._isTipping;
  }

  getTippingStatusEvent(): Subject<boolean> {
    return this.posSettingsService.getTippingStatusEvent();
  }

  getDefaultTippingProductEvent() {
    return this.posSettingsService.getDefaultTippingProductEvent();
  }

  get tipValue(): number {
    return this._tip.getValue()?.amount;
  }

  getTipEvent() {
    return this._tip;
  }

  setTipEvent(event: TippingEventPayload) {
    this._tip.next(event);
  }

  getTippingProduct() {
    return this.product;
  }

  get productUuid() {
    return this._productUuid;
  }

  private setTippingProduct() {
    if (!this.isTipping && !this.product) {
      return;
    }
    const productSettings = this.posSettingsService.getDefaultTipProduct();
    if (productSettings) {
      this._productUuid = productSettings.uuid;
      this.dbDaoService
        .getDataByUUID(UPDATES_TYPES.Product.type, productSettings.uuid)
        .then((data) => {
          this.product = new Product(data.data);
        })
        .catch((err) => this.logger.error(err, 'setTippingProduct:getDataByUUID', undefined, { productUuid: productSettings.uuid }));
    }
  }

  hasTipInvoiceEntry(invoiceUuid: string) {
    return this.invoicesTipInvoiceEntries.get(invoiceUuid);
  }

  hasOnlyTippingInvoiceEntry(invoice: Invoice): boolean {
    return invoice.getActiveInvoiceEntries().some((invoiceEntry) => !invoiceEntry.isTipping);
  }

  createTipInvoiceEntry(isExternalTipping: boolean) {
    if (!this.isTipping || !this.product) {
      return null;
    }
    const [variant] = this.product.variants as any;
    const productCategoryUuid: string = this.product.productCategoriesUuid.split(',')[0];
    const productCategory: string = productCategoryUuid === 'root' ? null : productCategoryUuid;
    const invoiceEntry: InvoiceEntry = this.invoicesService.makeInvoiceEntryToAddData(variant, this.product, '', productCategory) as InvoiceEntry;
    invoiceEntry.isExternalTipping = isExternalTipping;
    return invoiceEntry;
  }

  clearInvoiceEntryUuids(): void {
    this.invoicesTipInvoiceEntries.clear();
  }

  addTippingInvoiceEntryToInvoice(
      invoice: Invoice,
      guestNumber: number = 1,
      isExternalTipping: boolean = false,
      currentCompanyCurrencyRounding?: string): Invoice
  {

    if (!this.isTipping) {
      return invoice;
    }
    let invoiceWithTipping: Invoice = null;
    if (this.tipValue > 0) {
        const newInvoiceEntry: InvoiceEntry = this.createTipInvoiceEntry(isExternalTipping);
        newInvoiceEntry.price = this.tipValue;
        newInvoiceEntry.guestNumber = guestNumber;
        newInvoiceEntry.isTipping = true;

        if (isExternalTipping) {
          newInvoiceEntry.isExternalTipping = isExternalTipping;
        }

        invoiceWithTipping = this.invoicesService.addInvoiceEntryToInvoice(invoice, newInvoiceEntry, PRODUCT_TYPES.TIPS, 1, guestNumber);

        this.invoicesTipInvoiceEntries.set(invoice.uuid, newInvoiceEntry.uuid);
    } else {
     invoiceWithTipping = new Invoice(invoice);
    }

    this.invoicesService.calculateInvoiceAmountAfterDiscount(invoiceWithTipping, currentCompanyCurrencyRounding);
    return invoiceWithTipping;
  }
}
