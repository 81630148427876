import { Injectable } from '@angular/core';
import { Employee } from '@pos-common/classes/employee.class';
import { LocalStorage } from '@pos-common/services/utils/localstorage.utils';
import { PushNotifications } from '@pos-common/classes/capacitor-plugins/capacitor-plugins.class';
import { PlatformService } from '../platform/platform.service';
import { IntercomUserUpdateOptions, Intercom } from '@paymash/capacitor-intercom-plugin';
import { Company } from '@pos-common/classes/company.class';
import { PAYMASH_PROFILE } from '@profile';
import { LogService } from '@pos-common/services/system/logger';
import { LANGUAGES } from '@pos-common/constants';
import { AuthApiService } from '@pos-common/services/api/auth-api.service';

@Injectable()
export class IntercomService {
  private readonly logger = this.logService.createLogger('IntercomService');

  constructor(
    private localStorage: LocalStorage,
    private platformService: PlatformService,
    private logService: LogService,
    private authApiService: AuthApiService
  ) {
    this.init().catch((error) => this.logger.error(error, 'init error'));
  }

  public async init() {
    if (this.platformService.isIOS) await PushNotifications.register();
    if (this.platformService.isWeb) await Intercom['start'](PAYMASH_PROFILE.intercom.appId);
  }

  public async registerEmployee(company: Company, employee: Employee) {
    const userHash = await this.authApiService.getIntercomUserHash(this.platformService.currentPlatform);
    await Intercom.registerIdentifiedUser({
      userId: employee.uuid,
      email: employee.email,
    });
    await this.setUserHash(employee, userHash);

    if (this.platformService.isAndroid) {
      await Intercom.hideLauncher();
    }
    const userOptions = this.getUserOptions(company, employee);
    await this.updateUser(userOptions);
  }

  public shutdown(): Promise<void> {
    return Intercom.logout();
  }

  public show(): Promise<void> {
    return Intercom.displayMessenger();
  }

  public restart(company: Company, employee: Employee): Promise<void> {
    return this.shutdown()
      .then(() => this.registerEmployee(company, employee))
      .catch((error) => this.logger.error(error, 'Restart intercom error'));
  }

  private updateUser(_options: IntercomUserUpdateOptions): Promise<void> {
    return Intercom.updateUser(_options);
  }

  private setUserHash(employee: Employee, userHash: string): Promise<void> {
    if (this.platformService.isWeb) {
      const options = {
        userId: employee.uuid,
        email: employee.email,
        customAttributes: {
          user_hash: userHash,
        },
      };
      return this.updateUser(options);
    }
    return Intercom.setUserHash({ hmac: userHash });
  }

  private getUserOptions(company: Company, employee: Employee): IntercomUserUpdateOptions {
    return {
      userId: employee.uuid,
      email: employee.email,
      name: employee.employeeDisplayName,
      languageOverride: LANGUAGES.de,
      customAttributes: {
        companies: [
          {
            company_id: company.uuid,
            name: company.name,
            plan: {
              type: 'plan',
              name: 'Paid',
            },
          },
        ],
        is_manager: employee?.hasAdminPermission,
        can_cancel_invoice: employee?.hasCancellationPermission,
        app_type: PAYMASH_PROFILE.intercom.appType,
        currency: company.locale.currency,
        is_restaurant_enabled: company.isRestaurantEnabled,
        vat_number: company.vatNumber,
        performance_score: this.localStorage.get('performanceTest') || null,
      },
    };
  }
}
