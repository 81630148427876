import { InvoiceEntry as InvoiceEntryEntity } from '@pos-common/classes/invoice-entry.class';

export class InvoiceEntry {
  public static fromInvoiceEntryEntity(data: InvoiceEntryEntity, image: string | undefined, guestUuid: string | undefined): InvoiceEntry {
    const name = data.name;
    const variantName = data.shortName;
    const count = data.quantity || 0;
    const price = data?.price || 0;
    const totalPrice = data.discountedPrice;
    const isDiscount = !!(data.discount || data.discountPercentage);
    const uniqueIdentifier = data.uuid || null;

    return new InvoiceEntry(name, variantName, count, price, totalPrice, isDiscount, image, uniqueIdentifier, guestUuid);
  }

  public static createDiscount(name: string, totalPrice: number, price: number, uniqueIdentifier: string): InvoiceEntry {
    return new InvoiceEntry(name, undefined, totalPrice, price, totalPrice, true, undefined, uniqueIdentifier, undefined);
  }

  public name: string;
  public variantName: string | undefined;
  public count: number;
  public price: number;
  public totalPrice: number;
  public isDiscount: boolean;
  public image: string | undefined;
  public uniqueIdentifier: string;
  public guestUuid: string | undefined;

  constructor(
    name: string | undefined,
    variantName: string | undefined,
    count: number,
    price: number,
    totalPrice: number,
    isDiscount: boolean,
    image: string | undefined,
    uniqueIdentifier: string,
    guestUuid: string | undefined
  ) {
    this.name = name;
    this.variantName = variantName;
    this.count = count;
    this.price = price;
    this.totalPrice = totalPrice;
    this.isDiscount = isDiscount;
    this.image = image;
    this.uniqueIdentifier = uniqueIdentifier;
    this.guestUuid = guestUuid;
  }
}

export enum InvoiceEntryDiscountIdentifier {
  InvoiceDiscountCash = 'invoice-discount-cash',
  InvoiceDiscountPercent = 'invoice-discount-percent',
  CustomerDiscountCash = 'customer-discount-cash',
  CustomerDiscountPercent = 'customer-discount-percent',
}

export const InvoiceEntryDiscountIdentifiers: string[] = [
  InvoiceEntryDiscountIdentifier.InvoiceDiscountCash,
  InvoiceEntryDiscountIdentifier.InvoiceDiscountPercent,
  InvoiceEntryDiscountIdentifier.CustomerDiscountCash,
  InvoiceEntryDiscountIdentifier.CustomerDiscountPercent,
];

export const isNotDiscountEntry = (entry: InvoiceEntry) =>
  !InvoiceEntryDiscountIdentifiers.includes(entry.uniqueIdentifier);
