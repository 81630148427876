import { Injectable } from '@angular/core';
import { PAYMENT_PROVIDERS, PAYMENT_TRANSACTION } from '../../constants/payment-providers.const';
import { AvailableCurrencies } from '@pos-common/constants/mypos.const';
import { PaymentResult } from '../../classes/payment-result.class';
import { TimApiService } from './timApi.service';
import { IngenicoService } from './ingenico.service';
import { PaymentError } from '../../constants/payment-error.enum';
import { MyPosFacade } from '@pos-modules/my-pos/my-pos.facade';
import { MyPosMiniFacade } from '@pos-modules/my-pos-mini/my-pos-mini.facade';
import { TranslateService } from '@ngx-translate/core';
import { MyPosService } from '@pos-common/services/system/devices/my-pos/my-pos.service';
import { SumUpService } from './sumup/sumup.service';
import { AdyenPaymentService } from '@pos-common/services/system/adyen';
import { PaymentProcessingService } from '@pos-common/components/payment-processing/payment-processing.service';
import { PaymentProcessingActions } from '@pos-common/components/payment-processing/payment-processing-actions.enum';
import { LogService } from './logger/log.service';
import { StorageKeys } from '@pos-common/constants/storage.const';
import { LocalStorage } from '../utils/localstorage.utils';
import { PaymentMethodsApiService } from '../api/payment-methods-api.service';
import { PaymentMethodsDbEntityProvider } from '../resources/payment-methods-db-entity.provider';
import { map, switchMap } from 'rxjs/operators';
import { PaymentMethod } from '@pos-common/classes/payment-method.class';
import { DefaultPaymentMethods, REGEXPS } from '@pos-common/constants';
import { of } from 'rxjs';
import { MyposGlassService } from '@pos-common/services/system/mypos-glass/mypos-glass.service';
import { SecuredResponse } from '@pos-common/classes/secured-response.class';
import { NetworkService } from '@pos-common/services/system/network.service';
import { AdyenUtils } from '@pos-common/services/system/adyen/adyen-utils';

@Injectable()
export class PaymentService {
  private readonly logger = this.logService.createLogger('PaymentService');
  constructor(
    private readonly paymentProcessingService: PaymentProcessingService,
    private readonly network: NetworkService,
    private readonly timApiService: TimApiService,
    private readonly myPosFacade: MyPosFacade,
    private readonly myPosMiniFacade: MyPosMiniFacade,
    private readonly IngenicoService: IngenicoService,
    private readonly translateService: TranslateService,
    private readonly myPosService: MyPosService,
    private readonly myPosGlassService: MyposGlassService,
    private readonly sumUpService: SumUpService,
    private readonly adyenPaymentService: AdyenPaymentService,
    private readonly adyenUtils: AdyenUtils,
    private readonly paymentMethodsApiService: PaymentMethodsApiService,
    private readonly paymentMethodsProvider: PaymentMethodsDbEntityProvider,
    private readonly localStorageService: LocalStorage,
    private readonly logService: LogService,
    private readonly myposGlassService: MyposGlassService
  ) {}

  makeTransaction({
    paymentProvider,
    amount,
    currency,
    transactionType,
    invoiceUuid,
    paymentMethod,
    paymentUuid,
  }: {
    paymentProvider: PAYMENT_PROVIDERS;
    amount: number;
    currency: string;
    transactionType: string;
    invoiceUuid: string;
    paymentMethod: string;
    paymentUuid?: string;
  }): Promise<PaymentResult> {
    return new Promise(async (resolve, reject) => {
      paymentMethod = paymentMethod.toUpperCase();
      const error = this.checkErrorBeforeTransaction(paymentProvider, amount, currency, transactionType, paymentMethod);
      if (error) {
        reject(error);
        return;
      }
      const activeTerminal = this.localStorageService.getObject(StorageKeys.activeTerminal);
      this.logger.debug('makeTransaction:paymentProvider and activeTerminal', { paymentProvider, activeTerminal });
      switch (paymentProvider) {
        case PAYMENT_PROVIDERS.SUMUP:
          this.sumUpService.makeTransaction(amount).then(resolve).catch(reject);
          break;
        case PAYMENT_PROVIDERS.SIX:
          this.timApiService.makeTransaction(amount, currency).then(resolve).catch(reject);
          break;
        case PAYMENT_PROVIDERS.OPI:
          if (transactionType === PAYMENT_TRANSACTION.PAYMENT_REFUND) {
            this.IngenicoService.makeRefundTransaction(amount.toFixed(2).toString()).then(resolve).catch(reject);
          } else if (transactionType === PAYMENT_TRANSACTION.PAYMENT_REVERSAL) {
            this.IngenicoService.makeReversalTransaction().then(resolve).catch(reject);
          } else {
            this.IngenicoService.initIngenicoPayment(amount.toFixed(2).toString()).then(resolve).catch(reject);
          }
          break;
        case PAYMENT_PROVIDERS.MYPOS:
          if (this.myPosService.isMyPosTerminal) {
            const language = this.translateService.currentLang;
            this.myPosMiniFacade.makeTransaction(currency, language, amount, transactionType).then(resolve).catch(reject);
            return;
          }

          if (!this.myPosService.isMyPosDevice) {
            reject(PaymentError.NO_MYPOS_FOUND);
            break;
          }

          this.myPosFacade.makeTransaction({ amount, currency, transactionType, invoiceUuid, paymentMethod }).then(resolve).catch(reject);
          break;
        case PAYMENT_PROVIDERS.PAYMASH_PAY:
          if (!this.adyenUtils.isPaymashPayTerminal) {
            reject(PaymentError.NO_PAYMASHPAY_ACTIVE_TERMINAL);
            break;
          }
          if (transactionType === PAYMENT_TRANSACTION.PAYMENT_REFUND || amount < 0) {
            this.adyenPaymentService
              .makeRefund({ amount: Math.abs(amount), currency, paymentUuid, paymentMethod })
              .then(resolve)
              .catch(reject);
          } else if (!transactionType || transactionType === PAYMENT_TRANSACTION.PAYMENT_PURCHASE) {
            this.adyenPaymentService.makePayment({ amount, currency, paymentUuid, paymentMethod }).then(resolve).catch(reject);
          }
          break;
        case PAYMENT_PROVIDERS.MYPOSGLASS:
          if (!this.myPosGlassService.isActiveMyPosGlassTerminal) {
            reject(PaymentError.NO_MYPOS_GLASS_ACTIVE_TERMINAL);
            break;
          }

          if (this.network.getConnectionStatus()) {
            reject(PaymentError.TRANSACTION_NOT_POSSIBLE_CONNECT_FAIL);
            break;
          }

          if (transactionType === PAYMENT_TRANSACTION.PAYMENT_REFUND || amount < 0) {
            this.myposGlassService
              .makeRefund({
                amount,
                currency,
              })
              .then(resolve)
              .catch(reject);
          } else if (!transactionType || transactionType === PAYMENT_TRANSACTION.PAYMENT_PURCHASE) {
            this.myposGlassService
              .makePayment({
                amount,
                currency,
                foreignTransactionId: invoiceUuid,
              })
              .then(resolve)
              .catch(reject);
          }
          break;
      }
    });
  }

  private checkErrorBeforeTransaction(
    paymentProvider: PAYMENT_PROVIDERS,
    amount: number,
    currency: string,
    transactionType: string,
    paymentMethod: string
  ): string {
    const isPurchase = transactionType === PAYMENT_TRANSACTION.PAYMENT_PURCHASE;
    const isRefund = !isPurchase;
    const isReimbursement = isPurchase && amount < 0;
    const isRefundReimbursement = isRefund && amount < 0;
    const isZeroAmount = amount === 0;
    const isMyPosProvider = paymentProvider === PAYMENT_PROVIDERS.MYPOS;
    const isMyPosGlassProvider = paymentProvider === PAYMENT_PROVIDERS.MYPOSGLASS;
    const isPaymashPayProvider = paymentProvider === PAYMENT_PROVIDERS.PAYMASH_PAY;
    const isSixProvider = paymentProvider === PAYMENT_PROVIDERS.SIX;
    const isSumUpProvider = paymentProvider === PAYMENT_PROVIDERS.SUMUP;
    const isTwint = paymentMethod === DefaultPaymentMethods.TWINT;
    const supportedRefundProviders = [
      PAYMENT_PROVIDERS.OPI,
      PAYMENT_PROVIDERS.MYPOS,
      PAYMENT_PROVIDERS.MYPOSGLASS,
      PAYMENT_PROVIDERS.PAYMASH_PAY,
    ];
    const isSupportedRefundProviders = supportedRefundProviders.includes(paymentProvider);

    if (!isSupportedRefundProviders && (isRefund || isReimbursement)) {
      return PaymentError.REFUND_NOT_SUPPORTED;
    }
    if (isSupportedRefundProviders && isRefundReimbursement) {
      return PaymentError.REFUND_NOT_SUPPORTED;
    }
    if (isPaymashPayProvider && isTwint && (isRefund || isReimbursement)) {
      return PaymentError.REFUND_NOT_SUPPORTED;
    }
    if ((isPaymashPayProvider || isMyPosProvider || isMyPosGlassProvider || isSixProvider || isTwint || isSumUpProvider) && isZeroAmount) {
      return PaymentError.AMOUNT_ZERO_NOT_SUPPORTED;
    }

    if (isMyPosProvider) {
      if (AvailableCurrencies.indexOf(currency.toLocaleUpperCase()) === -1) {
        return PaymentError.NO_MYPOS_CURRENCY;
      }
      const { isMyPosTerminal, isMyPosDevice } = this.myPosService;
      if (!isMyPosTerminal && !isMyPosDevice) {
        return PaymentError.NO_MYPOS_FOUND;
      }
    }
    return;
  }

  public activeTerminals() {
    if (this.IngenicoService.checkIngenicoTerminal()) return PAYMENT_PROVIDERS.OPI;
    if (this.timApiService.getTerminalInstantly().viewName) return PAYMENT_PROVIDERS.SIX;
    return null;
  }

  showPaymentError(err: any) {
    const values = Object.values(PaymentError);
    if (values.includes(err)) {
      if (err === PaymentError.MYPOS_INVALID_CURRENCY) {
        return;
      }
      const message = this.translateService.instant(err);
      this.paymentProcessingService.init();
      this.paymentProcessingService.dispatchAction(PaymentProcessingActions.retry, {
        message,
        retryButtonOff: true,
      });
    }
  }

  /**
   * Create new uniq payment method
   *
   * @param { string } name - name for new payment method
   * @param { boolean } [isFullCheckName = false] - an optional flag specifying how to check for the presence of this
   * type of payment provider name for the client:
   * - "true" check by full match (using ===),
   * - "false" partial compliance check (using indexOf).
   */
  createNewPaymentMethod(name: string, isFullCheckName = false): Promise<PaymentMethod> {
    return this.paymentMethodsProvider
      .getListByParams({ deleted: false })
      .pipe(
        switchMap((paymentMethods: PaymentMethod[]) => {
          const currentName: string = name.toLowerCase().replace(REGEXPS.ANY_SPACES, '');
          const paymentMethod: PaymentMethod = this.hasPaymentMethod(currentName, paymentMethods, isFullCheckName);
          return !!paymentMethod ? of(paymentMethod) : this.paymentMethodsApiService.create(name)
            .pipe(map((response: SecuredResponse) => response?.data));
        })
      )
      .toPromise()
      .catch((error) => {
        this.logger.error(error, 'createNewPaymentMethod');
      });
  }

  private hasPaymentMethod(name: string, methods: PaymentMethod[], isFullCheck: boolean): PaymentMethod {
    const someTypeCheckingFn = isFullCheck
      ? (paymentMethod: PaymentMethod): boolean => paymentMethod?.name?.toLowerCase().replace(REGEXPS.ANY_SPACES, '') === name
      : (paymentMethod: PaymentMethod): boolean => name.indexOf(paymentMethod?.name?.toLowerCase().replace(REGEXPS.ANY_SPACES, '')) !== -1;

    return methods.find(someTypeCheckingFn);
  }
}
